import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';
import styles from './Blog.module.css';
import { FiChevronLeft } from 'react-icons/fi';
import { useCookies } from 'react-cookie';
import CookieSettings from '../components/Cookies/CookieSettings';

interface IProps extends RouteComponentProps<{ id: string }> {}

const PrivacyNotice: React.FC<IProps> = () => {
    const [cookies, setCookie] = useCookies(['maethril_consent']);
    const [cookiesOpen, setCookiesOpen] = useState<boolean>(false);

    return (
        <div className={styles.mainWrapper}>
            <article className={styles.mainContainer} itemScope itemType="http://schema.org/Article">
                <Helmet>
                    <title>Privacy Notice | Maethril</title>
                    <meta name="description" content="Privacy Notice" />
                </Helmet>

                <meta itemProp="datePublished" content="Jun 1, 2021" />
                <meta itemProp="publisher" content="Maethril" />

                <div className={styles.contentContainer}>
                    <header className={styles.header}>
                        <h1 itemProp="name headline">Maethril's Privacy Notice</h1>
                        <div className={styles.headerInfo}>
                            {
                                <div className={styles.authorAvatarContainer}>
                                    <img className={styles.avatarImg}
                                        src="/media/Maethril-250x250.png"
                                        alt="Maethril" />
                                </div>
                            }

                            <div className={styles.info}>
                                <p className={styles.authorName} data-tip data-for="authorInfo">Maethril</p>
                                <p className={styles.lowPriorityInfo}>
                                    <span className={styles.lpLeft} />
                                    Jun 1, 2021
                                    <span className={styles.infoDivider}>·</span>
                                    1 hour read
                                </p>
                            </div>
                        </div>
                    </header>
                    <section className={styles.content} itemProp="articleBody">
                        <p className="ql-align-justify">
                            Welcome to Maethril! Our privacy notice explains what info Maethril collects when you visit
                            or use the Maethril services, as well as ways we might use or share it.
                            We’re only describing our own practices - this notice doesn’t apply to third parties
                            we don’t own or control, including other companies you might interact with on or through the Maethril services
                            (see <a href="#third-party">Third-Party Websites And Services</a>).
                        </p>
                        <p className="ql-align-justify">
                            When we say “Maethril” (or similar things like <strong>“Company”</strong>, <strong>“we”</strong>, <strong>“us”</strong> or <strong>“our”</strong>),
                            we’re referring to the Maethril entity or, where applicable, entities that control
                            and are responsible for your information.
                        </p>
                        <p className="ql-align-justify">
                            When we say “Maethril services” (or similar things like <strong>“services”</strong>, <strong>“our services”</strong>),
                            we mean any games, websites, applications, and services that link to this notice,
                            regardless of how you access or use them or engage with us, including through mobile devices
                            or offline such as by phone. Your use of the Maethril services is also subject
                            to the <Link to="/terms-of-service">Terms of Service</Link> in your region
                            (including its provisions regarding limitations on damages, resolution of disputes,
                            and application of governing law). Please read this entire notice to make sure you understand it.
                            You should also read the <Link to="/terms-of-service">Terms of Service</Link> carefully,
                            and make sure you understand and agree to them before using the Maethril services.
                        </p>
                        <p className="ql-align-justify">
                            If you don’t understand any parts of the notice, <a href="#contact-us">Contact Us</a>.
                        </p>

                        <h2>Table of Contents</h2>
                        <div className={styles.tableOfContents}>
                            <ul>
                                <li>
                                    <h4><a href="#what-info">1. Info We Colllect</a></h4>
                                    <ul>
                                        <li><a href="#info-you">Info You Provide</a></li>
                                        <li><a href="#info-auto">Info We Collect Automatically</a></li>
                                        <li><a href="#info-other">Info We Collect From Third Parties</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <h4><a href="#how-info">2. How We Colllect Info</a></h4>
                                    <ul>
                                        <li><a href="#info-cookie">Cookies & Related Technologies</a></li>
                                        <li><a href="#info-analytics">Analytics & Interest-Based Ads</a></li>
                                        <li><a href="#info-facebook">Facebook Fan Page</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <h4><a href="#use-info">3. How We Use And Share Info</a></h4>
                                    <ul>
                                        <li><a href="#bases-personal-info">Legal Bases</a></li>
                                        <li><a href="#use-personal-info">Using Info</a></li>
                                        <li><a href="#share-personal-info">Sharing Info</a></li>
                                        <li><a href="#chat-personal-info">Chat & Player Behavior</a></li>
                                        <li><a href="#fraud-personal-info">Anti-Cheat & Fraud Prevention</a></li>
                                    </ul>
                                </li>
                                <li><h4><a href="#third-party">4. Third-Party Websites And Services</a></h4></li>
                                <li><h4><a href="#international-data">5. International Data Processing</a></h4></li>
                                <li><h4><a href="#your-choices">6. Your Choices And Controls</a></h4></li>
                                <li><h4><a href="#security">7. Security</a></h4></li>
                                <li><h4><a href="#updates-revisions">8. Updates And Revisions</a></h4></li>
                                <li><h4><a href="#contact-us">9. Contact Us</a></h4></li>
                            </ul>
                        </div>

                        <h2 id="what-info">1. Info We Collect</h2>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We collect info in three main ways:
                        </p>
                        <ul className="ql-align-justify">
                            <li>You provide some of it directly<br/>(such as by registering for an account).</li>
                            <li>We record some of it automatically<br/>(including with technologies like cookies).</li>
                            <li>We receive some of it from third parties<br/>(like social media companies).</li>
                        </ul>
                        <p className="ql-align-justify">
                            The particular kinds of info we gather usually depend on how you interact with us,
                            like which Maethril services you use and how you do so.
                        </p>

                        <h4 id="info-you">A. Info You Provide</h4>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We collect info you choose to provide or give to us in any manner.
                            You don’t have to share info when we ask you for it, but if you decline,
                            you might not be able to access certain Maethril services or take advantage of their full functionality.
                        </p>
                        <p className="ql-align-justify">
                            We collect info you enter or otherwise provide on the Maethril services, including:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                Your email address, date of birth, in-game name
                                (e.g. Maethril account username or nickname), and similar contact info
                                (like when you register for an account);</li>
                            <li>
                                Your username, password, and other details that help us secure and provide
                                access to the Maethril services (like when you login to our games or websites);
                            </li>
                            <li>
                                Your name, billing address, telephone number, payment method, and other details we collect
                                to help process purchases you make (like when you buy Maethril Points or shop at our Merch Store);
                            </li>
                            <li>
                                Your support-related info, including the nature of your concern and your account or order details
                                (like when you request help from us, such as by submitting a Player Support ticket);
                            </li>
                            <li>
                                Your preferences, interests, and general demographic info
                                (such as your hobbies, favorite games, or other info you share, like when you respond to a survey);
                            </li>
                            <li>
                                Info you share with us in connection with contests or promotions
                                (like to submit an entry, prove your eligibility, or claim a prize if you win);
                            </li>
                            <li>
                                Your Maethril account info (like your preferred game settings, key bindings, friends list);
                            </li>
                            <li>
                                Info you decide to give us (which may include info about your health)
                                so that we can better make the Maethril services accessible to you by accommodating specific
                                needs you may have, such as being deaf, hard of hearing, blind or visually impaired.
                            </li>
                        </ul>
                        <p className="ql-align-justify">
                            The Maethril services also offer social features like forums and chat.
                            When you use these features, the info you share is viewable by others -
                            this means we may collect and use the info you provide
                            including to enforce our <Link to="/terms-of-service">Terms of Service</Link>, such as described
                            in <a href="#fraud-personal-info">
                                Anti-Cheat & Fraud Prevention
                            </a> and <a href="#chat-personal-info">
                                Chat & Player Behavior
                            </a> below),
                            and in some cases it may also be collected or used by others without your knowledge
                            (for example, if you’re chatting with another player while they stream a match).
                        </p>
                        <p className="ql-align-justify">
                            When you play multiplayer games like Hexa, note that some info will be publicly accessible to others,
                            such as your game statistics and performance. We may record gameplay and related info
                            (such as Maethril account name and in-game actions), and replay or otherwise make it available
                            to other players without further notice to you. For example we may use gameplay footage
                            to help promote the Maethril services.
                        </p>
                        <p className="ql-align-justify">
                            If you disclose any information relating to other people to us or
                            to our service providers connection with the Maethril services,
                            you represent that you have the authority to do so and to permit us to use
                            the information in accordance with this privacy notice.
                        </p>

                        <h4 id="info-auto">B. Info We Collect Automatically</h4>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We automatically collect some info about how you interact
                            with and navigate the Maethril services, as well as the device and software you use to do so.
                        </p>
                        <p className="ql-align-justify">
                            Common examples include info regarding:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                Your use of the Maethril services (such as timestamps, clicks, scrolling, browsing times,
                                searches, referral/exit pages, and in-game activity and interactions);
                            </li>
                            <li>
                                Your computer or device (such as IP addresses, unique device IDs, processing capabilities,
                                manufacturer and model, language and other regional settings, geographic location,
                                and screen resolution and similar settings);
                            </li>
                            <li>
                                Your connection to the Maethril services, including details about
                                the network and software you’re using (such as browser type and version,
                                operating system name and version, ISP, and your preference settings;
                            </li>
                            <li>
                                How the Maethril services perform, including problems you may encounter
                                (such as loading errors and response times).
                            </li>
                        </ul>
                        <p className="ql-align-justify">
                            We may use technologies like cookies and our own servers to help us colect and store this info,
                            including in log files (see <a href="#how-info">How We Collect Info</a>).
                        </p>

                        <h4 id="info-other">C. Info We Collect From Third Parties</h4>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> Sometimes we obtain info from third parties to use along with the info we collect.
                            For example, if you link your Facebook account with your Maethril account, Facebook
                            may share info with us in accordance with Facebook’s privacy notice and your privacy settings on it.
                            Facebook also shares info with us about your use of our official Maethril
                            and game specific fan pages on Facebook. We also, for example, gather advertising
                            and analytics info from third parties to help support our marketing initiatives,
                            improve the Maethril services, and better manage our ad campaigns.
                        </p>
                        <p className="ql-align-justify">
                            We may collect info from third parties to supplement the info you provide
                            and the info we collect automatically for our legitimate purposes. For example:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                We obtain info from third parties if you use their services
                                in connection with the Maethril services
                                (such as linking your Facebook account with the Maethril services).
                                Your privacy settings on the third party’s service control what info they can share with us
                                - it can include info like your name, profile picture, gender, age range, username, language,
                                country, your friends and other info you have made available on the platform.
                                Please make sure you’re comfortable with what they might share by reviewing their posted policies,
                                and if applicable, modifying your privacy settings directly on their service.
                            </li>
                            <li>
                                We gather advertising and analytics info from third parties to help support our marketing initiatives,
                                improve the Maethril services, and better manage our ad campaigns
                                (like by displaying more personalized ads and measuring how effective they are).
                                To learn more about these companies, see <a href="#info-analytics">Analytics & Interest-Based Ads</a>.
                            </li>
                            <li>
                                We collect info from third parties to help us identify
                                from where you’re using the Maethril services (like your approximate location
                                based on your IP address). This helps us, for example, process purchases
                                (like assessing tax obligations), prevent fraud or abuse of the Maethril services
                                (such as identifying suspicious transactions or activity) and customize your visit
                                (like displaying the Maethril services in your local language).
                            </li>
                            <li>
                                We’ll also receive info from third-party platforms (like the Apple appstore or Google Play)
                                if you download the Maethril services (such as the Hexa app) on your mobile device.
                                This might include, for example, technical details (like device IDs) and the fact
                                you’ve downloaded one of our apps.
                            </li>
                            <li>
                                Facebook provides us with info about your use of our fan pages on Facebook.
                                To learn more about this, see <a href="#info-facebook">Facebook Fan Pages</a>.
                            </li>
                        </ul>

                        <h2 id="how-info">2. How We Collect Info</h2>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> In addition to info you provide, we and our partners
                            automatically record some info about how you use and navigate the Maethril services.
                            We may combine this info with other info we gather from or about you as described in this notice,
                            including to enforce our <Link to="/terms-of-service">Terms of Service</Link> and for analytics
                            and advertising purposes.
                        </p>

                        <h4 id="info-cookie">A. Cookies & Related Technologies</h4>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We use our own servers and technologies like cookies to automatically collect
                            and store info about how you interact with the Maethril services, as well as the device you use to do so.
                        </p>
                        <p className="ql-align-justify">
                            “Cookies” are small pieces of data that online services you use place on your device
                            for record-keeping and identification purposes. “Web beacons” are transparent pixel images
                            that allow online services to collect info about the ways you interact with them (like whether
                            you’ve opened an email or clicked on an ad). Similar common technologies include things like tags,
                            scripts, local shared objects (e.g., Flash cookies), local storage (e.g., HTML5), and related tools.
                        </p>
                        <p className="ql-align-justify">
                            The Maethril services use cookies, web beacons, and similar technologies to automatically collect, store,
                            and read files on your device. They may be delivered in a first-party (i.e., by Maethril) or third-party
                            (i.e., by other companies) context. These tools help the Maethril services work and operate more efficiently,
                            as well as capture info about how you interact with us (see <a href="#what-info">Info we collect</a>).
                        </p>
                        <p className="ql-align-justify">
                            We also use our servers in connection with these technologies to collect info about the ways you interact
                            with the Maethril services and store it in log files. This may include for example, info that your device,
                            browser, or operating system sends to us when you access the Maethril services (like device IDs, IP addresses,
                            and hardware or software specifications) and details about how you use your Maethril account
                            (like the particular features you use, your in-game interactions and statistics, and other similar info.
                        </p>
                        <p className="ql-align-justify">
                            We use info gathered through our servers and technologies like cookies for several reasons
                            based on Maethril’, third parties’ or players’ legitimate interest, including:
                        </p>
                        <ul className="ql-align-justify">
                            <li>To monitor, secure, and maintain the Maethril services (like preventing fraud);</li>
                            <li>To enhance your browsing experience (such as allowing you to log-in to your account);</li>
                            <li>To analyze and improve our operations (like aggregating traffic and usage patterns);</li>
                            <li>To control the display of ads (including providing you with targeted offers);</li>
                            <li>
                                To support and gauge the effectiveness of our communications and marketing campaigns
                                (like letting us know if you’ve clicked on our ads or opened our emails);
                            </li>
                            <li>
                                To better personalize your visit and store your preferences and settings
                                (for example, displaying content in your local language);
                            </li>
                            <li>
                                To otherwise manage our relationship with players and provide the Maethril services
                                under <Link to="/terms-of-service">Terms of Service</Link> (including help prevent toxicity
                                and ensure a fair gaming environment, such as described
                                in <a href="#fraud-personal-info">Anti-Cheat & Fraud Prevention</a> and <a href="#chat-personal-info">Chat & Player Behavior</a> below).
                            </li>
                        </ul>
                        <p className="ql-align-justify">
                            You can learn more about cookies and similar technologies, including details of how to manage
                            or disable them, by reading our Cookies Policy,
                            and by visiting <a href="https://aboutcookies.org/">https://aboutcookies.org/</a> or
                            consulting your browser’s privacy features. Some ways of managing traditional cookies
                            may not impact the use of other technologies, but certain browsers may offer their own tools
                            for removing HTML5. For additional details about managing Flash cookies,
                            click <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">here</a>.
                        </p>
                        <p className="ql-align-justify">
                            Please note that disabling technologies like cookies may prevent you from using the full functionality
                            of the Maethril services and the Maethril services may not work as we intended.
                        </p>

                        <h4 id="info-analytics">B. Analytics & Interest-Based Ads</h4>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We partner with third parties (like content providers, analytics companies,
                            and advertising networks) to help us improve the Maethril services and better understand
                            how you interact with them, as well as support our marketing initiatives. These companies
                            may collect info from you automatically in connection with your visit.
                        </p>
                        <p className="ql-align-justify">
                            In addition to the technologies we place, other companies may set their own cookies
                            or similar tools when you visit the Maethril services. This includes vendors we’ve engaged
                            to provide services on our behalf (like website analytics), as well as third parties that deliver content
                            (like videos from YouTube) or offers (like ads from ad networks) contained on the Maethril services.
                            We may receive reports based on our partners’ use of these tools on an individual or aggregate basis.
                        </p>
                        <p className="ql-align-justify">
                            For example, we use third-party analytics providers (like Google Analytics) to help evaluate
                            and report on use of the Maethril services. We also partner with ad companies to support
                            our marketing efforts, including by serving you ads better tailored to your likely interests
                            (such as based on your browsing activity on the Maethril services and when visiting other online services).
                            These companies may set  and read their own cookies and similar technologies to gather info about
                            your online activities across websites and services.
                        </p>
                        <p className="ql-align-justify">
                            Some analytics providers and ad companies offer you choices about how they collect and
                            use info directly on their websites. To learn more about opting out of Google Analytics,
                            click <a href="https://tools.google.com/dlpage/gaoptout">here</a>. For a representative
                            list of third-party advertising and analytics partners that operate on the Maethril services,
                            as well as information about how to opt-out to the extent applicable,
                            click <a href="#info-cookie">here</a>. You may also be able to opt-out in some cases
                            by visiting <a href="https://youradchoices.com/">https://<wbr />yourad<wbr />choices.com/</a> or <a href="https://www.networkadvertising.org/choices">www.network<wbr />advertising.org/choices/</a>, or
                            if you're located in the EU or Canada, <a href="https://www.youronlinechoices.eu/">https://<wbr />www.your<wbr />online<wbr />choices.eu/</a> or <a href="https://youradchoices.ca/">https://<wbr />yourad<wbr />choices.<wbr />ca/</a> respectively.
                            You can learn more about cookies and similar technologies, including details of how to manage or disable them,
                            by reading our <Link to="/cookie-notice">Cookies Policy</Link>.
                        </p>
                        <p className="ql-align-justify">
                            Please note that opting-out of interest-based advertising won’t necessarily entirely prevent
                            info from being collected automatically on the Maethril services, and it doesn’t mean you’ll
                            receive fewer ads or stop them from being displayed. Instead, the ads you do receive won’t be
                            interest-based any longer and therefore might be less relevant to your interests.
                        </p>

                        <h4 id="info-facebook">C. Facebook Fan Pages</h4>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> Maethril has Facebook official fan pages. These include official Maethril
                            and game specific fan pages. Facebook collects data from these pages according to its privacy policy
                            and provides statistics and insights to Maethril to help us understand the types of actions people
                            take on our fan pages (“insights data”).
                        </p>
                        <p className="ql-align-justify">
                            You can find out more about how FAcebook collects insights data and what info it shares with
                            us <a href="https://www.facebook.com/legal/terms/information_about_page_insights_data">here</a> (“insights data”).
                            The type of insights we receive from Facebook on our fan pages extend to our reach and engagement with our players,
                            the elements of the Maethril services people think work or don’t work and statistics and demographics
                            on who views our posts on our pages such as their age, gender, or location. You can also see the essence
                            of our arrangement with Facebook in terms of how we have allocated data protection responsibilities between us
                            regarding insights data <a href="https://www.facebook.com/legal/terms/page_controller_addendum">here</a>.
                        </p>

                        <h2 id="use-info">3. How We Use And Share Info</h2>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We use and share your info in accordance with legal bases set our below
                            to help us provide, operate, improve, understand, customize, support and market the Maethril services.
                        </p>

                        <h4 id="bases-personal-info">A. Legal Basis</h4>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We use and share your info (1) to provide the services under the Terms, (2) with your consent,
                            (3) in our others’ legitimate interests (such as safety and security, and providing a tailored service),
                            and (4) for other legal reasons.
                        </p>
                        <p className="ql-align-justify">
                            We rely on a number of legal bases to collect, use, share, and otherwise process the info we have
                            about you for the purposes described in this notice. These legal bases include:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                as is necessary to perform the <Link to="/terms-of-service">Terms of Service</Link> and to
                                provide the Maethril services;
                            </li>
                            <li>where you have consented to the processing, which you may revoke at any time;</li>
                            <li>for Maethril to comply with legal obligation, a court order, or to exercise and defend legal claims;</li>
                            <li>occasionally to protect your vital interests, or those of others;</li>
                            <li>where necessary in the public interest;</li>
                            <li>
                                where necessary for the purpose of Maethril’ or a third party’s legitimate interests,
                                such as those of visitors, users or partners.
                            </li>
                        </ul>
                        <p className="ql-align-justify">
                            To find out which specific legal bases we rely upon to use info about you for a particular purpose,
                            go to <a href="#use-personal-info">Using Info</a> and <a href="#share-personal-info">Sharing your info</a>.
                        </p>

                        <h4 id="use-personal-info">B. Using Info</h4>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We use the info we collect (both individually and in combination with
                            other info collected under this notice) to help us operate our business,
                            provide and improve our products and services, communicate with you, and advertise effectively.
                            The particular ways we use your info often depend on how you choose to use the Maethril services.
                        </p>
                        <p className="ql-align-justify">
                            Some of the most common examples of how we use info include:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                To provide the Maethril services to you, respond to inquiries, fulfil requests,
                                and process transactions (like helping you create an account, find a match,
                                and resolve Player Supports issues). We engage in these activities to manage
                                our contractual relationship with you and, where relevant, to comply with legal obligation
                                (e.g. related to payment transactions) or based upon our legitimate interest in doing so
                                (such as to provide the Maethril services to children below the age of legal capacity to enter a contract);
                            </li>
                            <li>
                                To better make the Maethril services accessible to you by accommodating specific needs
                                you may have such as visual or hearing impairment by using text to voice or voice to text functionality.
                                We only do this with your consent when you give us info about you for this specific purpose.
                                You can withdraw that consent at any time.
                            </li>
                            <li>
                                To deliver and tailor our communications with you, like by sending you important account-related
                                announcements or promotional communications that we believe may interest you (for details
                                about managing your communication options,
                                check out <a href="#your-choices">Your Choices and Controls</a> below).
                                We engage in these activities with your consent, where we have a legitimate interest
                                (e.g. to promote the Maethril services and keep you interested in them through personalised messages) or,
                                in the case of account-related announcements, to perform our contract with you;
                            </li>
                            <li>
                                To administer surveys, contests, sweepstakes, and similar offerings (such as confirming
                                your eligibility or sending you a prize if you win). We engage in these activities
                                to manage our contractual relationship with you, or for our legitimate interest
                                (e.g. to promote the Maethril services and keep you interested in them);
                            </li>
                            <li>
                                To monitor, analyze, protect, test, and improve our services and operations
                                (like measuring how the Maethril services perform, tracking usage patterns,
                                and fixing bugs or errors). We engage in these activities to manage our
                                contractual relationship with you, to comply with a legal obligation (e.g. to keep
                                the Maethril services secure), and/or because we have a legitimate interest
                                (e.g. understanding how the Maethril services are used, keep them secure and improve them
                                and develop new products);
                            </li>
                            <li>
                                To research and better understand the Maethril services and how they’re used.
                                We engage in these activities with your consent and/or because we have a legitimate interest
                                (e.g. understanding how the Maethril services are used, keep them secure, improve them,
                                develop new products and understand the impacts and reach of the Maethril services);
                            </li>
                            <li>
                                To promote products and services and measure the effectiveness of those campaigns.
                                We engage in these activities with your consent or for our and our partners’ legitimate interest
                                (marketing our and others’ products and services and understanding the effectiveness
                                of our marketing campaigns);
                            </li>
                            <li>
                                To personalize your Maethril experience, including by presenting content or features better tailored
                                to your interests (see <a href="#info-analytics">Analytics & Interest-Based Ads</a>). We engage
                                in these activities with your consent or there we have a legitimate interest
                                (e.g. improving our Maethril services by making them more personalised);
                            </li>
                            <li>
                                To improve and provide updates for the Maethril services
                                (like patching our games and releasing new features). We engage in these activities to manage
                                our contractual relationship with you, to comply with a legal obligation, and/or because
                                we have a legitimate interest (e.g. understanding how the Maethril services are used, keeping them secure,
                                improving them, developing new products and understand the impact and react of the Maethril services);
                            </li>
                            <li>
                                To operate and expand our business. We engage in these activities to manage our contractual
                                relationship with you, to comply with a legal obligation, and/or because we have a legitimate
                                interest in operating and expanding our business;
                            </li>
                            <li>
                                To facilitate use of or integration with the Maethril services, social sharing,
                                and related functionality (such as supporting chat features and forums). We engage in these activities
                                with your consent or where we have a legitimate interest (e.g. increasing customer engagement with
                                our products and the Maethril services).
                            </li>
                        </ul>
                        <p className="ql-align-justify">
                            We may also use, disclose, and preserve info as necessary or appropriate, including when necessary
                            for a legal obligation or legitimate or public interest or to protect the vital interest
                            of you or someone else, such as to:
                        </p>
                        <ul className="ql-align-justify">'
                            <li>
                                Comply with applicable law or respond to legal process
                                (like requests from law enforcement or other public or government authorities);
                            </li>
                            <li>Securely operate the Maethril services (like stopping attacks on our systems);</li>
                            <li>Protect users and third parties (such as to help prevent serious injury or crime);</li>
                            <li>
                                Protect our own rights, property, and operations (like enforcing
                                our contracts and terms - including the Terms of Service and pursuing available remedies
                                or limiting the damages we may sustain).
                            </li>
                        </ul>

                        <h4 id="share-personal-info">C. Sharing Info</h4>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We don’t share your contact info (such as your email or home address)
                            with independent third parties without your knowledge expect as described in this notice
                            (like to enforce the Terms of Service, secure the Maethril services, or if we’re required
                            to do so by law or respond to legal process). We don’t sell your information to independent
                            third parties. We do share non-contact info (like your Maethril account name, game stats, in-game activities,
                            and other aggregate or anonymous info), including publicly via the Maethril API.
                        </p>
                        <p className="ql-align-justify">
                            If we ever stop providing Maethril services or certain parts of them, we may share your info with the buyer.
                             Lastly, remember the stuff you share in social features (like chat and forums) is public
                             - please be careful about how you use them!
                        </p>
                        <p className="ql-align-justify">
                            We share info with service providers that operate on our behalf for the purposes discussed in this notice
                            (see <a href="#use-personal-info">3. B.</a> above). For example, when you buy something
                            on the Maethril services, we’ll share your payment-related info with entities we’ve engaged
                            to process payments and provide similar financial services (like fraud prevention).
                            Other common examples include partners that help us store data, send marketing emails,
                            and provide Player Support services, assist with research and analysis of our services,
                            third party social media platforms or apps we permit to link to our products or Maethril services.
                            We may also transfer your info in some cases to our professional advisers
                            (who are sometimes independent ‘data controllers’) such as lawyers and accountants
                            (e.g. when enforcing our contract with you).
                        </p>
                        <p className="ql-align-justify">
                            If you share info publicly through interactive features we control, such as forums and chat,
                            you should understand that this info becomes viewable to others. We reserve the right
                            (but have no obligation) to monitor your use of them, including for purposes like enforcing
                            our <Link to="/terms-of-service">Terms of Service</Link> and applicable
                            player behaviour requirements
                            (see <a href="#chat-personal-info">Chat & Player Behavior</a> and <a href="#fraud-personal-info">Anti-Cheat & Fraud Prevention</a>).
                            To request removal of your info from forums or similar features controlled by Maethril, <a href="#contact-us">Contact Us</a>.
                        </p>
                        <p className="ql-align-justify">
                            We may disclose player info in the event of any reorganization, merger, sale, joint venture, assignment,
                            transfer, or other disposition of all or any portion of our business, assets, or stock
                            (including in connection with any bankruptcy or similar proceedings). This means your info may be
                            one of the assets transferred to the acquiring entity in such situations. Please note that
                            your in-game name (e.g. Maethril account name) and gameplay statistics are viewable to others
                            and may be shared in-game and out-of-game with players and other third parties.
                        </p>
                        <p className="ql-align-justify">
                            We may disclose player info in the event of any reorganization, merger, sale, joint venture, assignment,
                            transfer, or other disposition of all or any portion of our business, assets, or stock
                            (including in connection with any bankruptcy or similar proceedings). This means your info may be
                            one of the assets transferred to the acquiring entity in such situations, according
                            to Maethril’ legitimate interest in engaging in such transactions and operating and expanding our business.
                        </p>
                        <p className="ql-align-justify">
                            We may also use, disclose, and preserve info to:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                Comply with applicable law or respond to legal process
                                (like requests from law enforcement or other public or government authorities),
                                including based upon our legitimate interest in doing so;
                            </li>
                            <li>
                                Securely operate the Maethril services (like stopping attacks on our systems),
                                based on Maethril’, players’ and third parties’ legitimate interest
                                in the Maethril services being a safe and secure environment;
                            </li>
                            <li>
                                Protect players and third parties (such as to help prevent serious injury or crime),
                                based on Maethril’, players’ and third parties’ legitimate interest
                                in the Maethril services being safe and secure environment;
                            </li>
                            <li>
                                Protect our own rights, property, and operations (like enforcing our contracts and terms
                                - including the Terms of Service - and pursuing available remedies
                                or limiting the damages we may sustain), based on Maethril’ legitimate interest
                                in protecting and preserving such and enforcing its legal rights.
                            </li>
                        </ul>

                        <h4 id="chat-personal-info">D. Chat & Player Behavior</h4>
                        <p className="ql-align-justify">
                            We’ve empowered our players to help police compliance with our policies related
                            to certain in-game behaviour. For example, players may report each other
                            and submit descriptions of their in-game activity and actions to help us
                            determine if the reported behaviour constituted a violation of rules and procedures
                            that apply to player conduct, as well as any potential repercussions
                            (such as temporary or permanent account suspension, chat restrictions or shadow bans).
                        </p>
                        <p className="ql-align-justify">
                            We also record and store, and reserve the right (but have no obligation) to monitor,
                            chat and similar details about in-game interactions for a period we determine
                            is appropriate to help us, among other things, detect and prevent toxicity,
                            enforce our rules and policies and Terms of Services, and foster a more positive gaming community
                            for our players. We engage in these activities to manage our contractual relationship with you,
                            comply with a legal obligation (e.g. to keep the Maethril services safe and secure),
                            and/or because we have a legitimate interest (e.g. understanding how
                            the Maethril services are used, keep them secure and improve them).
                        </p>
                        <p className="ql-align-justify">
                            We use both manual (like enabling you to report such behaviour through Player Support tickets)
                            and automated (such as machine learning) tools and techniques to support these efforts.
                        </p>
                        <p className="ql-align-justify">
                            To enable us to monitor compliance with our policies, we develop template patterns
                            of inappropriate behaviour (e.g. verbal abuse, hate speech, cheating, offensive or inappropriate name calling),
                            which we consider indicative of prohibited behaviours. We do this based upon an analysis
                            of actual behaviours on the Maethril services. We grade these patterns of inappropriate behaviour
                            based upon their severity with different consequences (e.g. temporary or permanent account restrictions
                            or chat restrictions) for players based upon how serious their conduct is. If another player makes
                            a complaint about your behaviour, our automated tools use these patterns of inappropriate behaviour
                            and grading system to compare your behaviour over time with these patterns of inappropriate behaviour
                            and, if you have engaged in inappropriate behaviour, make a decision as to the consequences for you
                            of your behaviour. This primarily helps us enforce our rules and policies, secure the Maethril services,
                            detect and prevent unauthorized activity, and maintain the competitive integrity of our games.
                        </p>
                        <p className="ql-align-justify">
                            Our complaints procedure allows you to appeal a decision. In this case, we will provide you
                            a report showing the thought process and method behind the decision along with some tips on how to avoid
                            repeating such behaviour in the future. If you are still unhappy, you can request one of our agents
                            to review your case who will make a final determination as to the correct outcome manually, based on
                            the available evidence.
                        </p>
                        <p className="ql-align-justify">
                            Please be kind to other players, observe our policies related to player behavior
                            (including our <Link to="/terms-of-service">Terms of Service</Link>), and
                            always be careful what you share in chat!
                        </p>

                        <h4 id="fraud-personal-info">E. Anti-Cheat & Fraud Prevention</h4>
                        <p className="ql-align-justify">
                            We always seek to offer players a fair, fun, and competitive gaming experience on the Maethril services.
                            Our <Link to="/terms-of-service">Terms of Service</Link> strictly prohibit
                            use of unauthorized third-party programs that interact
                            with the Maethril services, including mods, hacks, cheats, scripts, bots, trainers,
                            and automation programs. When you create or use a Maethril account, purchase things from us,
                            play our games, or otherwise interact with the Maethril services, we may use anti-cheat
                            and fraud prevention technologies that may take automated decisions (such as temporary
                            or permanent account suspensions or chat restrictions) based on the info we collect from or about you
                            (see <a href="#auto-info">Info We Collect</a>). We engage in these activities
                            to manage our contractual relationship with you, to comply with a legal obligation
                            (e.g. to keep the Maethril services secure), and/or because we have
                            a legitimate interest (e.g. understanding how the Maethril services are used,
                            keep them secure and improve them).
                        </p>
                        <p className="ql-align-justify">
                            To enable us to monitor compliance with our anti-cheat and fraud prevention policies,
                            we develop template patterns of inappropriate behaviour (e.g. cheating, fraud),
                            which we consider indicative of prohibited behaviours. We do this based upon an analysis
                            of actual behaviours on the Maethril services. We grade these patterns
                            of inappropriate behaviour based upon their severity with different consequences
                            (e.g. temporary or permanent account restrictions or chat restrictions) for players
                            based upon how serious their conduct is. Our automated tools use these patterns of inappropriate behaviour
                            and grading system to compare your behaviour over time with these patterns of inappropriate behaviour and,
                            if you have engaged in inappropriate behaviour, make a decision as to the consequences for you
                            of your behaviour. This primarily helps us enforce our rules and policies, secure the Maethril services,
                            detect and prevent unauthorized activity, and maintain the competitive integrity of our games.
                        </p>
                        <p className="ql-align-justify">
                            Our complaints procedure allows you to appeal a decision. In this case,
                            we will provide you a report showing the thought process and method behind the decision
                            along with some tips on how to avoid repeating such behaviour in the future.
                            If you are still unhappy, you can request one of our agents to review your case who will
                            make a final determination as to the correct outcome manually, based on the available evidence.
                        </p>

                        <h2 id="third-party">4. Third-Party Websites And Services</h2>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We allow interactions with third parties to make your experience better,
                            but our notice doesn’t and can’t apply to entities we don’t own, control, or instruct.
                            We’re unable to guarantee that independent third parties adhere to the same practises as us,
                            so please be sure to read their policies and terms before sharing info with them.
                        </p>
                        <p className="ql-align-justify">
                            There are several ways you might share info with third-party websites or services in connection
                            with your visit, including:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                <em>External links</em>. If you click on links to external websites or services
                                that are operated by third parties, they may collect, use, and share your info pursuant
                                to their own policies (not ours). Including a link to or from third-party services
                                doesn’t mean we’ve endorsed their practices.
                            </li>
                            <li>
                                <em>Account Integrations</em>. The Maethril services use interfaces
                                that allow you to interact with third-party websites, applications, or similar services
                                during your visit, including on or through your Maethril account or an account
                                you’ve created with such third parties. For example, we partner with social media companies
                                to provide features that facilitate social sharing and connections (such as the Facebook “Like”
                                button and other widgets). These features may collect info about your interactions with them
                                and the Maethril services, and may use technologies like cookies to function properly.
                                How third-party companies like Facebook process and control your info is controlled
                                by their own privacy policies.
                            </li>
                            <li>
                                <em>Cookies & Related Technologies</em>. The Maethril services contain cookies,
                                web beacons, and other common technologies that help us collect and analyze info in connections
                                with your visit (see <a href="#info-auto">Info We Collect Automatically</a>).
                                Third parties (like analytics providers or ad networks) may set and read their
                                own tracking technologies on the Maethril services. If they do, they’re responsible
                                for using these tools as described in their respective privacy policies. For a representative
                                list of third-party advertising and analytics partners that operate on the Maethril services,
                                as well as information about how to opt-out to the extent applicable,
                                click <a href="#your-choices">here</a>.
                            </li>
                            <li>
                                <em>Our Facebook fan pages</em>. Maethril has Facebook official fan pages.
                                These include official Maethril and game specific fan pages. Facebook collects data
                                from these pages according to its privacy policy and provides statistics and insights
                                to Maethril to help us understand the types of actions people take on our fan pages.
                                Learn more about this in <a href="#info-facebook">Facebook Fan Pages</a>.
                            </li>
                        </ul>
                        <p className="ql-align-justify">
                            Please remember to contact third parties directly (and not Maethril)
                            if you have any questions or concerns about their practices.
                        </p>

                        <h2 id="international-data">5. International Data Processing</h2>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We’re a global company with operations around the world.
                            When you use the Maethril services, your info may be processed anywhere we or our partners do business.
                        </p>
                        <p className="ql-align-justify">
                            Our servers are located in Slovakia. If you are accessing our services from outside Slovakia,
                            please be aware that your information may be transferred to, stored, and processed
                            by us in our facilities and by those third parties with whom we may
                            share your personal information in other countries.
                        </p>

                        <h2 id="your-choices">6. Your Rights, Choices And Controls</h2>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> You have choices regarding your use of the Maethril services
                            and the ways we process info. Since you can interact with us in many different ways,
                            the particular controls available to you often depend on the nature of our relationship.
                        </p>
                        <p className="ql-align-justify">
                            You can use many parts of the Maethril services without ever creating an account,
                            purchasing our products or services, or otherwise submitting info like your name or email address to us.
                            If you do choose to visit our use the Maethril services, we generally retain the related
                            personal information as long as your account is active or as is otherwise necessary
                            to provide the Maethril services, operate our business (including for legitimate purposes
                            like complying with our legal obligations, managing internal books and records, preventing fraud,
                            resolving disputes, and enforcing our contracts and terms, such as this notice and
                            the <Link to="/terms-of-service">Terms of Service</Link> or to achieve the purpose
                            set out in this notice, unless a longer retention period is permitted or required by law).
                        </p>
                        <p className="ql-align-justify">
                            The precise periods for which we keep your personal information vary depending
                            on the nature of the information, why we need it and relevant legal or operational retention needs.
                            Factors we consider in determining these periods include the minimum required retention period
                            prescribed by law or recommended as best practice, the period during which a claim can be made with
                            respect to a contract or other matter, whether the personal information has been aggregated or pseudonymized,
                            and other relevant criteria. For example, the retention period applicable for your personal data needed
                            to provide the Maethril service to you (such as accessing the games) is limited to your account life.
                            When your account is deleted, Maethril will delete the relevant information for that purpose
                            (providing the service to you) but will keep some personal information (Maethril account name for instance)
                            for other defined purposes such as security and fraud detection. As another example, chat logs/history data
                            is retained for a period of two years from its creation. This is to enable Maethril to properly investigate
                            infringements of our rules and policies such as fraud, toxicity, cheating and harmful behaviour.
                        </p>
                        <p className="ql-align-justify">
                            You can also limit the info you share with third parties,
                            such as by choosing not to link your Maethril account with third-party services
                            (see <a href="#third-party">Third-Party Websites and Services</a>).
                        </p>
                        <p className="ql-align-justify">
                            We offer players choices about the types of communications they receive from Maethril.
                            If you choose to receive marketing and promotional emails from us but later change your mind,
                            simply “unsubscribe” by following the instructions in the relevant email.
                            These choices don’t apply to mandatory communications that are part of the Maethril services,
                            like important account-related notices.
                        </p>
                        <p className="ql-align-justify">
                            The Maethril services also have many built-in tools to help players exercise control
                            over their info directly. For example, you can generally delete old forum posts,
                            mute or disable voice chat functionality, and change other preferences from your account settings.
                            If you need help with any of these options, be sure to <a href="#contact-us">Contact Us</a>.
                        </p>
                        <p className="ql-align-justify">
                            Many Internet browsers allow you to reject, manage, and delete cookies.
                            You may also be able to manage other similar technologies, like HTML5, by changing your browser settings
                            (see <a href="#info-auto">Info We Collect Automatically)</a>.
                        </p>
                        <p className="ql-align-justify">
                            If you don’t want to take advantage of the services that third-party analytics and advertising
                            companies provide on the Maethril services, you may able to opt-out of some of them by
                            visiting <a href="https://www.aboutads.info">https://<wbr />www.about<wbr />ads.<wbr />info</a> or <a href="https://www.networkadvertising.org/choices/">www.network<wbr />advertising.org/choices/</a>, or
                            if you're located in the EU or Canada, <a href="https://www.youronlinechoices.eu/">https://<wbr />www.your<wbr />online<wbr />choices.eu/</a> or <a href="https://youradchoices.ca/">https://<wbr />yourad<wbr />choices.<wbr />ca/</a> respectively.
                            For representative list of third-party advertising and analytics partners that operate on the Maethril services,
                            as well as details about how to opt-out to the extent applicable,
                            click <a href="#your-choices">here</a>.
                        </p>
                        <p className="ql-align-justify">
                            If you’re concerned with the way we’re handling your info, or if you would like to request to access,
                            correct, update, restrict or delete your personal info, object to or opt out of the processing
                            of your personal info, withdraw your consent to our use of your info, or if you would like to request
                            a copy of your personal info for purposes of transmitting it to another company
                            (to the extent these rights are provided to you by applicable law), please <a href="#contact-us">Contact Us</a>.
                            Your right to object includes where we process your info for direct marketing,
                            for performing a task in the public interest or pursuing our legitimate interests or those of a third party.
                        </p>
                        <p className="ql-align-justify">
                            If you withdraw your consent (including for marketing purposes), it does not affect
                            the lawfulness of what we did up until that point.
                        </p>
                        <p className="ql-align-justify">
                            Before we process such requests, we may ask you to verify your identity
                            (like by logging-in to your account or providing us with certain info to help us confirm ownership)
                            and we may request payment where allowed by law. Note that asking us to remove personal info
                            or refrain from processing it may also result in account suspension or termination where
                            this info is necessary to make Maethril services available to you (we normally tell you this
                            at the point we collect the info), in which case you’ll no longer be able to access those Maethril services.
                            We may also retain certain info associated with your account for purposes like complying
                            with our legal obligations, managing internal books and records, preventing fraud, resolving disputes,
                            and enforcing our contracts and terms, such as this notice and
                            the <Link to="/terms-of-service">Terms of Service</Link>.
                        </p>

                        <h2 id="security">7. Security</h2>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We’re committed to securing player info and complying
                            with our obligations towards applicable data protection laws,
                            but can’t absolutely guarantee the security of any data we collect from you.
                            By choosing to share info with us (or anybody else online these days), you’re acknowledging this risk.
                        </p>
                        <p className="ql-align-justify">
                            We use appropriate physical, technical, and organizational security safeguards to help protect
                            your info from unauthorized access, use, or disclosure both during transmission and in storage.
                            For example, we use computer systems with limited access in controlled facilities to store info.
                            We also use technologies like encryption and hashing to protect some of the info we collect.
                        </p>
                        <p className="ql-align-justify">
                            However, please note that no server, communications network, or data transmission
                            over the Internet is 100% secure. The Maethril services are no exception.
                            We can’t guarantee the security of any info transmitted through the Maethril services
                            and make no assurances about our ability to prevent any such loss or misuse.
                        </p>
                        <p className="ql-align-justify">
                            It’s up to you whether you’d like to share info with us; if you do,
                            you’re acknowledging that you’re doing so with this risk.
                        </p>

                        <h2 id="updates-revisions">8. Updates And Revisions</h2>
                        <p className="ql-align-justify">
                            <strong>Summary:</strong> We’ll make changes to this policy over time
                            to keep you better informed about how we handle player info.
                        </p>
                        <p className="ql-align-justify">
                            We’ll update our privacy notice from time to time to reflect changes in technology, law,
                            our business operations, or any other reason we determine is necessary or appropriate.
                            When we do make changes, we’ll update the date at the top of the notice and post it
                            to the Maethril services. If we make material changes to the notice or the ways we process
                            player info, we’ll provide you additional notice as well (such as by prominently posting
                            a notice of the changes on the Maethril services before they take effect or
                            sending you a notification directly).
                        </p>
                        <p className="ql-align-justify">
                            We encourage you to check back periodically to review this notice for any changes since your last visit.
                            This will help ensure you better understand your relationship with Maethril, including the ways
                            we process your info.
                        </p>

                        <h2 id="contact-us">9. Contact Us</h2>
                        <p className="ql-align-justify">
                            If you have any questions about this notice or our privacy practises,
                            please send them to us via email at <a href="mailto:privacy@maethril.net">privacy@maethril.net</a>.
                        </p>
                    </section>
                    <div className={styles.readMoreContainer}>
                        <Link className={styles.readMoreLink} to='/'>
                            <FiChevronLeft />
                        </Link>
                    </div>
                    <div className={styles.newsletterContainer}>
                    </div>
                </div>
            </article>
            {
                cookies["maethril_consent"] &&
                    <CookieSettings open={cookiesOpen} setClose={() => setCookiesOpen(false)} />
            }
        </div>
    );
}

export default PrivacyNotice;
