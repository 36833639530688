import styles from './SocialMedia.module.css';

const MaethrilText: React.FC = () => {
    return (
        <svg className={styles.svgContainer} viewBox="0 0 918.4 106.3" preserveAspectRatio="xMidYMin">
            <g>
                <path d="M18.1,0l34.8,86.8L87.6,0h18.2v106.3h-14V64.9l1.3-44.7l-34.9,86.1H47.5L12.6,20.4L14,64.9v41.4H0V0H18.1z"/>
                <path d="M220.5,78.5H176l-10,27.8h-14.5L192.1,0h12.3L245,106.3h-14.4L220.5,78.5z M180.2,67h36.1l-18.1-49.7L180.2,67z"/>
                <path d="M349.4,57.2h-46.1v37.7h53.5v11.5h-67.5V0h66.8v11.5h-52.8v34.2h46.1V57.2z"/>
                <path d="M479.1,11.5h-34.2v94.9H431V11.5h-34.1V0h82.2V11.5z"/>
                <path d="M606.5,106.3h-14.1V57.2h-53.6v49.1h-14V0h14v45.7h53.6V0h14.1V106.3z"/>
                <path d="M700.3,63.3h-25v43h-14.1V0h35.2c12,0,21.2,2.7,27.6,8.2c6.5,5.5,9.7,13.4,9.7,23.8c0,6.6-1.8,12.4-5.4,17.3
                    c-3.6,4.9-8.6,8.6-14.9,11l25,45.1v0.9h-15L700.3,63.3z M675.3,51.8h21.5c7,0,12.5-1.8,16.6-5.4c4.1-3.6,6.2-8.4,6.2-14.5
                    c0-6.6-2-11.6-5.9-15.1c-3.9-3.5-9.6-5.3-17-5.3h-21.5V51.8z"/>
                <path d="M798.4,106.3h-14V0h14V106.3z"/>
                <path d="M868,94.9h50.4v11.5h-64.5V0H868V94.9z"/>
            </g>
        </svg>
    );
}

export default MaethrilText;

