import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';
import configData from '../config.json';
import { IBlog } from '../types/Blog';
import styles from './Blog.module.css';
import { FiFacebook, FiShare, FiTwitter, FiLinkedin, FiLink, FiChevronLeft } from "react-icons/fi";
import ReactTooltip from 'react-tooltip';
import SkeletonBlog from '../components/SkeletonBlog';
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton } from "react-share";
import Newsletter from '../components/Newsletter';
import Gist from "react-gist";
import { useCookies } from 'react-cookie';

interface IProps extends RouteComponentProps<{ id: string }> {}

const Blog: React.FC<IProps> = ({ match }) => {
    const [blog, setBlog] = useState<IBlog>();
    const [loadingBlog, setLoadingBlog] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const url:string = String(window.location);

    useEffect(() => {
        axios({
            method: 'GET',
            url: `${configData.BLOG_URL}${match.params.id}`
        })
            .then(response => setBlog(response.data))
            .catch(error => setError(error))
            .finally(() => setLoadingBlog(false));
    }, []);

    if(loadingBlog)
        return <SkeletonBlog />

    if(error || !blog)
    {
        return (
            <div className={styles.mainWrapper}>
                <article className={styles.mainContainer}>
                    <div className={styles.contentContainer}>
                        <h1>Blog post not found.</h1>
                    </div>
                </article>
            </div>
        );
    }

    return (
        <div className={styles.mainWrapper}>
            <article className={styles.mainContainer} itemScope itemType="http://schema.org/Article">
                <Helmet>
                    <title>{blog?.title} | Maethril</title>
                    <meta name="description" content={blog?.title} />
                </Helmet>

                <meta itemProp="datePublished" content={blog?.publishedDate} />
                <meta itemProp="publisher" content="Maethril" />

                <div className={styles.contentContainer}>
                    <header className={styles.header}>
                        <h1 itemProp="name headline">{blog?.title}</h1>

                        <div className={styles.actionsXs}>
                            <FiShare className={styles.share}
                                     data-tip='shareTooltipXs'
                                     data-for='shareTooltipXs'
                                     data-event="click focus"/>
                            <ReactTooltip id="shareTooltipXs"
                                          globalEventOff='click'
                                          effect="solid"
                                          place="right"
                                          clickable={true}
                                          className={styles.shareTooltipXs}>
                                <ul className={styles.shareTooltipBtnContainer}>
                                    <li>
                                        <TwitterShareButton url={url}
                                                            title={blog?.title}
                                                            hashtags={["Maethril", "Blog"]}
                                                            related={["Maethril"]}>
                                            <div className={styles.shareTooltipBtnContent}>
                                                <FiTwitter className={styles.shareTooltipIcon} />
                                                <p>Twitter</p>
                                            </div>
                                        </TwitterShareButton>
                                    </li>
                                    <li>
                                        <FacebookShareButton url={url}
                                                             quote={blog?.title}
                                                             hashtag={"#Maethril"}>
                                            <div className={styles.shareTooltipBtnContent}>
                                                <FiFacebook className={styles.shareTooltipIcon} />
                                                <p>Facebook</p>
                                            </div>
                                        </FacebookShareButton>
                                    </li>
                                    <li>
                                        <LinkedinShareButton url={url}
                                                             title={blog?.title}
                                                             source={"Maethril Blog"}>
                                            <div className={styles.shareTooltipBtnContent}>
                                                <FiLinkedin className={styles.shareTooltipIcon} />
                                                <p>LinkedIn</p>
                                            </div>
                                        </LinkedinShareButton>
                                    </li>
                                    <li onClick={() => {navigator.clipboard.writeText(url)}}>
                                        <div className={styles.shareTooltipBtnContent}>
                                            <FiLink className={styles.shareTooltipIcon} />
                                            <p>Copy link</p>
                                        </div>
                                    </li>
                                </ul>
                            </ReactTooltip>
                        </div>

                        <div className={styles.headerInfo}>
                            {
                                blog?.author &&
                                <div className={styles.authorAvatarContainer}>
                                    {
                                        blog?.author.profilePictureUrl ?
                                            <img className={styles.avatarImg}
                                                src={blog?.author.profilePictureUrl}
                                                alt={blog?.author.fullName} /> :
                                            <span>
                                                {blog?.author.fullName ? blog?.author.fullName[0] : "M" }
                                            </span>
                                    }
                                </div>
                            }

                            <div className={styles.info}>
                                {
                                    blog?.author &&
                                    <p className={styles.authorName} data-tip data-for="authorInfo">
                                         {blog?.author.fullName ? blog?.author.fullName : "Maethril"}
                                    </p>
                                }
                                {
                                    blog?.author &&
                                    <ReactTooltip id="authorInfo"
                                              effect="solid"
                                              place="right"
                                              className={styles.authorTooltip}>
                                        <div className={styles.authorTooltipHeader}>
                                            <div className={`${styles.authorAvatarContainer} ${styles.authorTooltipAvatar}`}>
                                                {
                                                    blog?.author.profilePictureUrl ?
                                                        <img className={styles.avatarImg}
                                                            src={blog?.author.profilePictureUrl}
                                                            alt={blog?.author.fullName} /> :
                                                        <span>
                                                            {blog?.author.fullName ? blog?.author.fullName[0] : "M" }
                                                        </span>
                                                }
                                            </div>
                                            <div className={styles.authorTooltipText}>
                                                <h2 className={styles.authorTooltipName}>
                                                    {blog?.author.fullName ? blog?.author.fullName : "Maethril"}
                                                </h2>
                                                <p className={styles.authorTooltipBio}>{blog?.author.bio}</p>
                                            </div>
                                        </div>
                                    </ReactTooltip>
                                }
                                <p className={styles.lowPriorityInfo}>
                                    <span className={styles.lpLeft} />
                                    {blog?.publishedDate}
                                    <span className={styles.infoDivider}>·</span>
                                    {blog?.estMinToRead} min read
                                </p>
                            </div>

                            <div className={styles.actionsSmAndUp}>
                                <FiShare className={styles.share}
                                            data-tip='shareTooltipSmAndUp'
                                            data-for='shareTooltipSmAndUp'
                                            data-event="click focus" />
                                <ReactTooltip id="shareTooltipSmAndUp"
                                              globalEventOff='click'
                                              effect="solid"
                                              place="bottom"
                                              clickable={true}
                                              className={styles.shareTooltipSmAndUp}>
                                    <ul className={styles.shareTooltipBtnContainer}>
                                        <li>
                                            <TwitterShareButton url={url}
                                                                title={blog?.title}
                                                                hashtags={["Maethril", "Blog"]}
                                                                related={["Maethril"]}>
                                                <div className={styles.shareTooltipBtnContent}>
                                                    <FiTwitter className={styles.shareTooltipIcon} />
                                                    <p>Twitter</p>
                                                </div>
                                            </TwitterShareButton>
                                        </li>
                                        <li>
                                            <FacebookShareButton url={url}
                                                                quote={blog?.title}
                                                                hashtag={"#Maethril"}>
                                                <div className={styles.shareTooltipBtnContent}>
                                                    <FiFacebook className={styles.shareTooltipIcon} />
                                                    <p>Facebook</p>
                                                </div>
                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url={url}
                                                                title={blog?.title}
                                                                source={"Maethril Blog"}>
                                                <div className={styles.shareTooltipBtnContent}>
                                                    <FiLinkedin className={styles.shareTooltipIcon} />
                                                    <p>LinkedIn</p>
                                                </div>
                                            </LinkedinShareButton>
                                        </li>
                                        <li onClick={() => {navigator.clipboard.writeText(url)}}>
                                            <div className={styles.shareTooltipBtnContent}>
                                                <FiLink className={styles.shareTooltipIcon} />
                                                <p>Copy link</p>
                                            </div>
                                        </li>
                                    </ul>
                                </ReactTooltip>
                            </div>
                        </div>
                    </header>
                    <section className={styles.content} itemProp="articleBody">
                        {
                            blog?.content.map(content => !content.isEmbed ?
                                <div dangerouslySetInnerHTML={{ __html: content.value as string }} /> :
                                <Gist id={content.value} />
                            )
                        }
                    </section>
                    <div className={styles.readMoreContainer}>
                        <Link className={styles.readMoreLink} to='/'>
                            <FiChevronLeft />
                        </Link>
                    </div>

                    <div className={styles.newsletterContainer}>
                        <Newsletter />
                    </div>
                </div>
            </article>
        </div>
    );
}

export default Blog;
