import React, { useState, useEffect } from 'react';
import configData from '../../config.json';
import Switch from "react-switch";
import { useCookies } from 'react-cookie';
import { FiX } from 'react-icons/fi';
import axios from 'axios';

import styles from './CookieSettingsMenu.module.css';
import CookieInfo from './CookieInfo';

interface ICookieSettings
{
    handleClose: () => void;
}

interface ICookie
{
    id: number;
    name: string;
    provider: string;
    expiration: string;
    purpose: string;
    project: string;
    type: number;
}

interface ICookieNotice
{
    cookies: ICookie[];
    wasFetched: boolean;
    show: boolean;
}

const CookieSettingsMenu: React.FC<ICookieSettings> = (props: ICookieSettings) => {
    const [cookies, setCookie] = useCookies(['maethril_consent']);

    const [essentialsCookies, setEssentialsCookies] = useState<ICookieNotice>({ cookies: [], wasFetched: false, show: false });
    const [marketingCookies, setMarketingCookies] = useState<ICookieNotice>({ cookies: [], wasFetched: false, show: false });
    const [personalizationCookies, setPersonalizationCookies] = useState<ICookieNotice>({ cookies: [], wasFetched: false, show: false });
    const [analyticsCookies, setAnalyticsCookies] = useState<ICookieNotice>({ cookies: [], wasFetched: false, show: false });

    const [marketing, setMarketing] = useState<boolean>(false);
    const [personalization, setPersonalization] = useState<boolean>(false);
    const [analytics, setAnalytics] = useState<boolean>(true);
    useEffect(() => {
        if(cookies["maethril_consent"])
        {
            setMarketing(cookies["maethril_consent"].marketing);
            setPersonalization(cookies["maethril_consent"].personalization);
            setAnalytics(cookies["maethril_consent"].analytics);
        }
    }, [])

    function toggleCookieNotice(type: string, show: boolean) {
        switch(type)
        {
            case "Essentials":
                !essentialsCookies.wasFetched ?
                    getCookies(type) :
                    setEssentialsCookies({...essentialsCookies, show: show});
                break;
            case "Marketing":
                !marketingCookies.wasFetched ?
                    getCookies(type) :
                    setMarketingCookies({...marketingCookies, show: show});
                break;
            case "Personalization":
                !personalizationCookies.wasFetched ?
                    getCookies(type) :
                    setPersonalizationCookies({...personalizationCookies, show: show});
                break;
            case "Analytics":
                !analyticsCookies.wasFetched ?
                    getCookies(type) :
                    setAnalyticsCookies({...analyticsCookies, show: show});
                break;
        }
    }

    function getCookies(type: string)
    {
        let data = {
            project: "MBlog",
            type: {
                "Essentials": 0,
                "Marketing": 1,
                "Personalization": 2,
                "Analytics": 3
            }[type]
        }

        axios({
            method: 'POST',
            url: configData.COOKIES_URL,
            data: data
        })
            .then(response => {
                switch(type)
                {
                    case "Essentials":
                        setEssentialsCookies({...essentialsCookies, cookies: response.data, wasFetched: true, show: true});
                        break;
                    case "Marketing":
                        setMarketingCookies({...marketingCookies, cookies: response.data, wasFetched: true, show: true});
                        break;
                    case "Personalization":
                        setPersonalizationCookies({...personalizationCookies, cookies: response.data, wasFetched: true, show: true});
                        break;
                    case "Analytics":
                        setAnalyticsCookies({...analyticsCookies, cookies: response.data, wasFetched: true, show: true});
                        break;
                }
            })
            .catch(error => {})
    }

    function handleSave() {
        let cookie = {
            marketing: marketing,
            personalization: personalization,
            analytics: analytics
        }

        let expiration = new Date();
        expiration.setDate(expiration.getDate() + 365);

        setCookie("maethril_consent", cookie, {
            path: "/",
            secure: true,
            sameSite: 'lax',
            expires: expiration
        });

        props.handleClose();
    }

    return (
        <div className={styles.background}
             onClick={props.handleClose}>
            <div className={styles.container}
                 onClick={(e) => {e.stopPropagation()}}>
                <header className={styles.containerHeader}>
                    <h2>Storage Preferences</h2>
                    <FiX className={styles.closeIcon}
                         onClick={props.handleClose}/>
                </header>
                <div className={styles.content}>
                    <p className={styles.contentDesc}>
                        When you visit websites, they may store or retrieve data in your browser.
                        This storage is often necessary for the basic functionality of the website.
                        The storage may be used for marketing, analytics, and personalization of the site,
                        such as storing your preferences. Privacy is important to us, so you have the option
                        of disabling certain types of storage that may not be necessary for the basic functioning
                        of the website. Blocking categories may impact your experience on the website.
                    </p>
                    <ul className={styles.cookieTypeList}>
                        <li className={styles.cookieType}>
                            <label className={styles.cookieTypeHeader}>
                                <span>Essential</span>
                                <Switch className={styles.switch}
                                        checked={true}
                                        onChange={() => {}}
                                        height={20} width={40} handleDiameter={18}
                                        disabled={true} />
                            </label>
                            <p>These items are required to enable basic website functionality.</p>
                            <div className={styles.cookieNoticeContainer}>
                                <div className={styles.cookieNoticeHeader}
                                    onClick={() => toggleCookieNotice("Essentials", !essentialsCookies.show)}>
                                    Cookie Notice
                                </div>
                                {
                                    essentialsCookies.show &&
                                    <ul className={styles.cookieNoticeList}>
                                        {
                                            essentialsCookies.cookies.length === 0 ?
                                                <p className={styles.noItems}>No Storage Items</p> :
                                                essentialsCookies.cookies.map(cookie => <CookieInfo key={cookie.name} {...cookie}/>)
                                        }
                                    </ul>
                                }
                            </div>
                        </li>
                        <li className={styles.cookieType}>
                            <label className={styles.cookieTypeHeader}>
                                <span>Marketing</span>
                                <Switch className={styles.switch}
                                        checked={marketing}
                                        onChange={() => setMarketing(!marketing)}
                                        height={20} width={40} handleDiameter={18} />
                            </label>
                            <p>
                                These items are used to deliver advertising that is more relevant to you and your interests.
                                They may also be used to limit the number of times you see an advertisement
                                and measure the effectiveness of advertising campaigns. Advertising networks usually place them
                                with the website operator’s permission.
                            </p>
                            <div className={styles.cookieNoticeContainer}>
                                <div className={styles.cookieNoticeHeader}
                                    onClick={() => toggleCookieNotice("Marketing", !marketingCookies.show)}>
                                    Cookie Notice
                                </div>
                                {
                                    marketingCookies.show &&
                                    <ul className={styles.cookieNoticeList}>
                                        {
                                            marketingCookies.cookies.length === 0 ?
                                                <p className={styles.noItems}>No Storage Items</p> :
                                                marketingCookies.cookies.map(cookie => <CookieInfo key={cookie.name} {...cookie}/>)
                                        }
                                    </ul>
                                }
                            </div>
                        </li>
                        <li className={styles.cookieType}>
                            <label className={styles.cookieTypeHeader}>
                                <span>Personalization</span>
                                <Switch className={styles.switch}
                                        checked={personalization}
                                        onChange={() => setPersonalization(!personalization)}
                                        height={20} width={40} handleDiameter={18} />
                            </label>
                            <p>
                                These items allow the website to remember choices you make (such as your user name,
                                language, or the region you are in) and provide enhanced, more personal features.
                                For example, a website may provide you with local weather reports or traffic news by
                                storing data about your current location.
                            </p>
                            <div className={styles.cookieNoticeContainer}>
                                <div className={styles.cookieNoticeHeader}
                                    onClick={() => toggleCookieNotice("Personalization", !personalizationCookies.show)}>
                                    Cookie Notice
                                </div>
                                {
                                    personalizationCookies.show &&
                                    <ul className={styles.cookieNoticeList}>
                                        {
                                            personalizationCookies.cookies.length === 0 ?
                                                <p className={styles.noItems}>No Storage Items</p> :
                                                personalizationCookies.cookies.map(cookie => <CookieInfo key={cookie.name} {...cookie}/>)
                                        }
                                    </ul>
                                }
                            </div>
                        </li>
                        <li className={styles.cookieType}>
                            <label className={styles.cookieTypeHeader}>
                                <span>Analytics</span>
                                <Switch className={styles.switch}
                                        checked={analytics}
                                        onChange={() => setAnalytics(!analytics)}
                                        height={20} width={40} handleDiameter={18} />
                            </label>
                            <p>
                                These items help the website operator understand how its website performs,
                                how visitors interact with the site, and whether there may be technical issues.
                                This storage type usually doesn’t collect information that identifies a visitor.
                            </p>
                            <div className={styles.cookieNoticeContainer}>
                                <div className={styles.cookieNoticeHeader}
                                    onClick={() => toggleCookieNotice("Analytics", !analyticsCookies.show)}>
                                    Cookie Notice
                                </div>
                                {
                                    analyticsCookies.show &&
                                    <ul className={styles.cookieNoticeList}>
                                        {
                                            analyticsCookies.cookies.length === 0 ?
                                                <p className={styles.noItems}>No Storage Items</p> :
                                                analyticsCookies.cookies.map(cookie => <CookieInfo key={cookie.name} {...cookie}/>)
                                        }
                                    </ul>
                                }
                            </div>
                        </li>
                    </ul>
                </div>
                <div className={styles.submitContainer}>
                    <button onClick={handleSave}>Save</button>
                </div>
            </div>
        </div>
    );
}

export default CookieSettingsMenu;
