import React from 'react';
import styles from './SkeletonBlog.module.css';

const SkeletonBlog: React.FC = () => {
    const contentItems: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

    return (
        <div className={styles.mainWrapper}>
            <article className={styles.mainContainer}>
                <div className={styles.contentContainer}>
                    <header className={styles.header}>
                        <div className={styles.title} />
                        <div className={styles.headerInfo}>
                            <div className={styles.authorAvatarContainer} />
                            <div className={styles.info} />
                        </div>
                    </header>
                    <section className={styles.content}>
                        { contentItems.map(obj => <div key={obj} className={styles.contentLine} />)}
                        <div className={styles.shorterContentLine} />
                    </section>
                </div>
            </article>
        </div>
    );
}

export default SkeletonBlog;
