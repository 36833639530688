import React, { useState } from 'react';

import styles from './CookieInfo.module.css';
import { FiSettings } from 'react-icons/fi';

interface ICookie
{
    id: number;
    name: string;
    provider: string;
    expiration: string;
    purpose: string;
    project: string;
    type: number;
}

const CookieInfo: React.FC<ICookie> = (props: ICookie) => {
    return (
        <li className={styles.container}>
            <dl>
                <dt>Name</dt>
                <dd>{props.name}</dd>
                <dt>Provider</dt>
                <dd>{props.provider}</dd>
                <dt>Expiration</dt>
                <dd>{props.expiration}</dd>
                <dt>Purpose</dt>
                <dd>{props.purpose}</dd>
            </dl>
        </li>
    );
}

export default CookieInfo;
