import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';
import styles from './Blog.module.css';
import { FiChevronLeft } from 'react-icons/fi';
import { useCookies } from 'react-cookie';
import CookieSettings from '../components/Cookies/CookieSettings';

interface IProps extends RouteComponentProps<{ id: string }> {}

const Terms: React.FC<IProps> = () => {
    const [cookies, setCookie] = useCookies(['maethril_consent']);
    const [cookiesOpen, setCookiesOpen] = useState<boolean>(false);

    return (
        <div className={styles.mainWrapper}>
             <article className={styles.mainContainer} itemScope itemType="http://schema.org/Article">
                <Helmet>
                    <title>Terms & Condition</title>
                    <meta name="description" content="Terms & Conditions" />
                </Helmet>

                <meta itemProp="datePublished" content="Jun 1, 2021" />
                <meta itemProp="publisher" content="Maethril" />

                <div className={styles.contentContainer}>
                    <header className={styles.header}>
                        <h1 itemProp="name headline">Maethril's Terms & Condition</h1>
                        <div className={styles.headerInfo}>
                            {
                                <div className={styles.authorAvatarContainer}>
                                    <img className={styles.avatarImg}
                                        src="/media/Maethril-250x250.png"
                                        alt="Maethril" />
                                </div>
                            }

                            <div className={styles.info}>
                                <p className={styles.authorName} data-tip data-for="authorInfo">Maethril</p>
                                <p className={styles.lowPriorityInfo}>
                                    <span className={styles.lpLeft} />
                                    Jun 1, 2021
                                    <span className={styles.infoDivider}>·</span>
                                    30 min read
                                </p>
                            </div>
                        </div>
                    </header>
                    <section className={styles.content} itemProp="articleBody">
                        <p className="ql-align-justify">
                            Welcome to Maethril! These terms of service (the <b>“Terms”</b>) set out the terms and conditions
                            by which Maethril offers you access to use and enjoy our games, apps, websites and other services
                            (the <b>“Maethril Services”</b>).
                        </p>
                        <p className="ql-align-justify">
                            Please read these Terms carefully and pay particular attention to these items:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                <b>Virtual Content.</b> When you click to purchase, earn or are gifted Virtual Content,
                                you only get a license to access Virtual Content. You have no ownership in any of the Virtual Content
                                you unlock and you can’t transfer it to someone else. Virtual Content has no monetary value,
                                is generally game specific and you can’t redeem Virtual Content for any type of “real world” money.
                            </li>
                            <li>
                                <b>Refund Policy.</b> You lose the right to change your mind, cancel the order and get
                                a refund if you get immediate access to or download your digital content.
                                There are some exceptions to this rule, but we have a strict policy on refunds.
                            </li>
                            <li>
                                <b>User Rules.</b> You agree to follow our User Rules (<a href="#section7"></a>Section 7).
                                These rules govern both in-game behavior and certain out-of-game conduct
                                while using the Maethril Services.
                            </li>
                        </ul>
                        <h4>Attention Parents!</h4>
                        <p className="ql-align-justify">
                            We provide game rating info on our website about age appropriateness.
                            We also encourage you to supervise your child’s online activities, review the content
                            they are accessing and, as appropriate, monitor their social interactions.
                            For more info on game ratings and content descriptors, please check your local ratings system.
                        </p>

                        <h2>Table of Contents</h2>
                        <div className={styles.tableOfContents}>
                            <ul>
                                <li><h4><a href="#section1">1. Your Account</a></h4></li>
                                <li><h4><a href="#section2">2. Account Termination</a></h4></li>
                                <li><h4><a href="#section3">3. Limited License</a></h4></li>
                                <li><h4><a href="#section4">4. Virtual Goods, Game Currency And Purchases</a></h4></li>
                                <li><h4><a href="#section5">5. Fees & Taxes</a></h4></li>
                                <li><h4><a href="#section6">6. Unsolicited Idea Submission Policy</a></h4></li>
                                <li><h4><a href="#section7">7. User Rules</a></h4></li>
                                <li><h4><a href="#section8">8. User Generated Content</a></h4></li>
                                <li><h4><a href="#section9">9. Monitoring</a></h4></li>
                                <li><h4><a href="#section10">10. Updates & Modifications</a></h4></li>
                                <li><h4><a href="#section11">11. Boards & Links</a></h4></li>
                                <li><h4><a href="#section12">12. Warranty Disclaimer</a></h4></li>
                                <li><h4><a href="#section13">13. Indemnification</a></h4></li>
                                <li><h4><a href="#section14">14. Limitation Of Liability</a></h4></li>
                                <li><h4><a href="#section15">15. Governing Law</a></h4></li>
                                <li><h4><a href="#section16">16. Additional Terms Applicable To Maethril Services</a></h4></li>
                                <li><h4><a href="#section17">17. Miscellaneous</a></h4></li>
                            </ul>
                        </div>

                        <h2 id="section1">1. Your Account</h2>
                        <h4>1.1 Am I eligible to create an account and use the Maethril Services?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> You either must be an adult or have permissions from your parents or legal guardian.
                        </p>
                        <p className="ql-align-justify">
                            To create an account and use the Maethril Services, you must: (i) be an adult;
                            (ii) be an emancipated minor; or (iii) have valid parent or legal guardian consent
                            to be bound by these Terms. If you’re not an adult or emancipated minor, or don’t understand
                            this section, please talk to your parents or legal guardian and ask for help. If you are the
                            parent or legal guardian of a minor who creates an account with us, you and the minor accept and
                            agree to be bound by these Terms. You are also responsible for all use of the account and compliance
                            with these Terms by the minor, including all purchases made on the account.
                            You may not create or use an account or use any of the Maethril Services on behalf of any other person
                            or other legal entity or for a commercial purpose.
                        </p>
                        <h4>1.2. Do I need to provide my real name and stuff?</h4>
                        <p className="ql-align-justify">
                            You must always supply us with accurate and complete info including your real name.
                        </p>
                        <h4>1.3. I have a ton of different email accounts. Does it matter which one I use to register my account?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Yes. Otherwise, you might miss important notices!
                        </p>
                        <p className="ql-align-justify">
                            We send account, legal and service related notices to the email address registered to your account.
                            These notifications may be really important (e.g., a notification that we intend to terminate
                            your account for inactivity) so you must keep the email address associated with your account current
                            and (if we ask) verified.
                        </p>
                        <h4>1.4. Can I share or sell my account or login credentials?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> No.
                        </p>
                        <p className="ql-align-justify">
                            When you create an account with us, we require you to select a unique username and password
                            (collectively, your <b>“Login Credentials”</b>). You agree that:
                        </p>
                        <ul className="ql-align-justify">
                            <li>you can’t share your account or Login Credentials with anyone;</li>
                            <li>
                                you can’t sell, transfer or allow any other person to access your account or Login Credentials,
                                or offer to do so;
                            </li>
                            <li>you must keep your Login Credentials secret.</li>
                        </ul>
                        <p className="ql-align-justify">
                            You must notify us immediately if you become aware of any breach of security, including any unauthorized account
                            access or any loss, theft or unauthorized use or disclosure of your Login Credentials or payment info so we can take
                            appropriate steps. You are responsible for all losses (including loss or use of Virtual Content) on your account
                            where you have shared your Login Credentials or have failed to keep your account or Login Credentials secure.
                        </p>

                        <h2 id="section2">2. Account Termination</h2>
                        <h4>2.1. How can my account be suspended or terminated?</h4>
                        <p className="ql-align-justify">
                            2.1.1 <b>You.</b> You may terminate or suspend your account at any time by contacting us
                            at <a href="mailto:support@maethril.net">support@maethril.net</a>.
                        </p>
                        <p className="ql-align-justify">
                            2.1.2 <b>Us.</b> We may terminate or suspend your account without notice to you
                            if we reasonably determine, that:
                        </p>
                        <ul className="ql-align-justify">
                            <li>you have breached any part of these Terms (including the User Rules);</li>
                            <li>
                                doing so would be in the best interests of our community or the Maethril Services or is required
                                for upholding a third party’s rights;
                            </li>
                            <li>
                                we have stopped offering the Maethril Services in your region (though we’ll normally
                                post advance notice on our website, app or game if we plan to stop offering
                                a material core feature of a game or all of the Maethril Services in your region);
                            </li>
                            <li>
                                you have failed to pay any fees you owe us (except in cases of our gross negligence
                                or willful misconduct) or we have to refund someone due to unauthorized use of
                                a payment made from your account;
                            </li>
                            <li>
                                you have (or we have reasonable grounds for suspecting that you have)
                                made unauthorized use of someone else’s payment info.
                            </li>
                        </ul>
                        <p className="ql-align-justify">
                            We can make such determinations, with the assistance of automated systems and machine learning tools
                            or by using other methods that we think are appropriate. If you think that we have made a mistake,
                            please Contact Us with details and we will review your case, though we may suspend your account
                            during our review. You can also challenge our determinations.
                        </p>
                        <h4>2.2. Will Maethril maintain my account forever, even if I stop using it?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> If you abandon your account for a very long period of time,
                            we may terminate it after giving you notice and an opportunity to re-activate it.
                        </p>
                        <p className="ql-align-justify">
                            If you do not use your account for a prolonged period of time, we reserve the right to take measures
                            against your account, including suspension or termination. If we plan to take measures against
                            your account based on prolonged period of inactivity, we will let you know first
                            (e.g., by email to the email address registered to your account) and give you ample opportunity
                            to avoid such measures (e.g., deletion of your account).
                        </p>
                        <h4>2.3. What happens if Maethril terminates my account?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> No games for you and you will lose access to any purchases
                            including Game Currency and Virtual Goods.
                        </p>
                        <p className="ql-align-justify">
                            If your account is terminated, you’ll no longer have access to your account,
                            including any of the associated data or Virtual Content (including Game Currency)
                            (though this does not limit or affect any rights you have under data or consumer protection laws).
                            Where we terminate for a legitimate reason (e.g. where you have breached these Terms):
                        </p>
                        <ul className="ql-align-justify">
                            <li>you’ll not be entitled to any refunds and we’ll have no liability to you;</li>
                            <li>
                                we also reserve the right to terminate any other accounts you may have created,
                                as well as your access to any other Maethril Services (also without any refunds or liability to you).
                            </li>
                        </ul>
                        <p className="ql-align-justify">
                            You understand and agree that using the Maethril Services comes with the risk that your account
                            may be terminated or suspended in accordance with these Terms and that, whenever you use
                            the Maethril Services, you’ll bear this risk in mind and always conduct yourself appropriately.
                        </p>

                        <h2 id="section3">3. Limited License</h2>
                        <h4>3.1. What can I do with the Maethril Services?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> You can enjoy the Maethril Services for your own, personal, non-commercial use.)
                        </p>
                        <p className="ql-align-justify">
                            We grant you a limited, non-exclusive, non-transferable, revocable license to use and enjoy the Maethril Services
                            (and any Virtual Content) for your individual, non-commercial, entertainment purposes only and expressly conditioned
                            upon your compliance with these Terms. If we terminate your account, any license granted by us to you
                            in the Maethril Services and any Virtual Content ends immediately. Unless otherwise expressly authorized
                            by us in a signed written contract, you may not sell, copy, exchange, loan, reverse engineer, decompile,
                            derive source code from, translate, lease, grant a security interest in, transfer, publish, assign or otherwise
                            distribute any of the Maethril Services or any of Maethril’ intellectual property,
                            including any of our computer code or Virtual Content.
                        </p>
                        <h4>3.2. Can I do stuff with Maethril’s intellectual property?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> We reserve all the rights to our IP, but do allow for some personal,
                            non-commercial uses, like fan art.)
                        </p>
                        <p className="ql-align-justify">
                            We (and our licensors) own and reserve all rights, title and interest in and to the Maethril Services,
                            and all data and content posted, generated, provided or otherwise made available in or through
                            the Maethril Services, including, user accounts, computer code, titles, objects, artifacts, characters,
                            character names, chat logs, game recordings and broadcasts, locations, location names, stories, dialog,
                            catch phrases, artwork, graphics, structural or landscape designs, animations, sounds,
                            musical compositions and recordings, virtual goods, in-game currency, audio-visual effects,
                            character likenesses, methods of operation and gameplay (collectively, <b>“Game Content”</b>).
                            For clarity, Game Content includes Virtual Content. You can’t create any work of authorship based
                            on the Game Content or Maethril Services except as expressly permitted by us.
                        </p>
                        <p className="ql-align-justify">
                            You also agree that unless we grant you a license, in a written contract signed by us,
                            you may never use any of our trademarks, service marks, trade names, logos, domain names,
                            taglines, or trade dress. Any reproduction, redistribution, or modification of the Maethril Services,
                            or use of the Maethril Services not in accordance with these Terms, is expressly prohibited
                            and may result in severe civil and/or criminal penalties.
                        </p>

                        <h2 id="section4">4. Virtual Goods, Game Currency And Purchases</h2>
                        <h4>4.1. What is Virtual Content?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Virtual content includes stuff like
                            game currency and virtual goods, such as skins, emotes, accessories, etc.)
                        </p>
                        <p className="ql-align-justify">
                            When using the Maethril Services, we may provide you with opportunities to acquire
                            a limited license to access virtual goods, such as skins, emotes, etc., (<b>“Virtual Goods”</b>)
                            and in-game currency (<b>“Game Currency”</b>), associated with your account
                            (collectively, <b>“Virtual Content”</b>).
                        </p>
                        <p className="ql-align-justify">
                            Game Currency may only be used for the specific game for which it was purchased unless
                            we say otherwise at the time of purchase.
                        </p>
                        <h4>4.2. How do I get a “license” to access Virtual Content?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Buy it, earn it, or receive it.
                        </p>
                        <p className="ql-align-justify">
                            We may provide you with various opportunities to acquire a limited license
                            to access Game Currency or Virtual Content, including:
                        </p>
                        <ul>
                            <li>purchasing it (e.g., with a credit card);</li>
                            <li>earning it (e.g., by accomplishing game missions or tasks);</li>
                            <li>receiving it (e.g., from another player as a gift)</li>
                        </ul>
                        <h4>4.3. Do I “own” the Virtual Content I unlock?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> No. What you “unlock” is not the virtual good itself, but rather,
                            a non-transferable limited license to access it.
                        </p>
                        <p className="ql-align-justify">
                            You have no ownership or other property interest in any of the Virtual Content you unlock,
                            regardless of how you acquired access to it. Virtual Content has no monetary value.
                            You can’t transfer (unless we allow it in the functionality of the Maethril Services)
                            or redeem Virtual Content for any type of “real world” money. You can’t obtain any refunds
                            for purchasing a license to access Virtual Content, except as expressly permitted by us.
                        </p>
                        <h4>4.4. I don’t own my Virtual Content?</h4>
                        <p className="ql-align-justify">
                            When you obtain Virtual Content from us, what we are actually giving you is a personal,
                            non-exclusive, non-transferable, non-sublicensable, revocable, limited right
                            and license to use that Virtual Content only in connection with your use
                            of the applicable Maethril Services.
                        </p>
                        <p className="ql-align-justify">
                            <b>
                                Notwithstanding anything to the contrary in these Terms, you acknowledge
                                and agree that you shall have no ownership or other property interest in your account,
                                and that all rights in and to your account are and shall forever be owned by and inure
                                to the benefit of Maethril. You further acknowledge and agree that you have no title,
                                ownership, or other proprietary interest in any Virtual Content, regardless of any
                                consideration offered or paid in exchange. Furthermore, except in cases of willful
                                misconduct or gross negligence, or to the extent these Terms say otherwise,
                                Maethril shall not be liable in any manner for the destruction, deletion, modification,
                                impairment, hacking, or any other damage or loss of any kind caused to Virtual Content,
                                including the deletion of Virtual Content upon the termination or expiration of your account
                                or our reasonable changes to the Maethril Services.
                            </b>
                        </p>
                        <h4>4.5. Will my Virtual Content always be available?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Not necessarily, no.
                        </p>
                        <p className="ql-align-justify">
                            In an effort to constantly improve the Maethril Services, evolve our games
                            and keep the Maethril Services, safe, fun, and secure, we have the right to delete,
                            alter, move, remove, re-package, re-price, or transfer any and all Game Content, including
                            Virtual Content, in whole or in part, at any time, with or without notice to you,
                            and with no liability of any kind to you. For example, your Virtual Goods may (and likely will)
                            evolve over time to improve the Maethril Services or for regulatory or legal reasons.
                            If we decide to entirely retire certain Virtual Goods that you recently purchased,
                            we will provide you with a replacement. We won’t delete your Game Currency without notice
                            (such as through posts on our website, app or game), unless your account is terminated by us
                            for a legitimate reason or by you under <a href="#section2">Section 2.1.1.</a> We may sometimes change the purchasing power
                            of Game Currency (for example, we might increase the number of Game Currency needed to purchase
                            Virtual Goods, such as skins). We normally only do this in incremental steps but we’ll give you notice
                            (such as through posts on our website, app or game) if we plan to make changes that will significantly
                            impact your Game Currency in a negative way. We don’t provide or guarantee, and expressly disclaim,
                            any value, cash or otherwise, attributed to any data residing on servers we operate or control,
                            including any Game Content or Virtual Content attributed to your account. If we allow the sale or
                            transfer of your right to access certain Game Content or Virtual Content, it may only be conducted
                            via services approved or provided by us, if any.
                        </p>

                        <h2 id="section5">5. Fees & Taxes</h2>
                        <h4>5.1 Is everything about the Maethril Services free?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> No.
                        </p>
                        <p className="ql-align-justify">
                            Some aspects of the Maethril Services may require you to pay a fee,
                            and you agree that you’ll provide accurate and complete payment info to us or
                            the third-party payment provider used by us. You further agree to pay all fees
                            and applicable taxes incurred on your account. We may revise the pricing for any part
                            of the Maethril Services at any time. This can impact on the purchasing power of your
                            Game Currency, though we normally only do this in incremental steps. All fees and charges
                            are payable in accordance with payment terms in effect at the time the fee or the charge becomes
                            due and payable. We may, from time to time, modify, amend, or supplement our fees and fee-billing
                            methods, and such changes shall be effective immediately upon posting in these Terms
                            or elsewhere on our websites, apps or in our games. Except in cases of our gross negligence
                            or willful misconduct, if you fail to pay any fees you owe to us or we have to refund someone
                            due to unauthorized use of a payment made from your account, we may suspend or terminate your account.
                            See <a href="#section2">Section 2</a> (Account Termination) for more info.
                        </p>
                        <p className="ql-align-justify">
                            <b>
                                Subject to applicable law, any applicable fees and other charges for fee-based services
                                (including Game Currency) are payable in advance and aren’t refundable in whole or in part except
                                as expressly provided in these Terms. Except in cases of Maethril’s gross negligence,
                                or willful misconduct or to the extent these Terms say otherwise, you’re fully liable
                                for all charges to your account, including any unauthorized charges.
                            </b>
                        </p>
                        <h4>5.2 What are my responsibilities relating to Game Currency?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Pay applicable taxes. Verify that all the transactions with us are correct, etc.
                        </p>
                        <p className="ql-align-justify">
                            You’re solely responsible for paying any applicable taxes related to acquiring,
                            using, or accessing Game Currency. Game Currency may be sold or issued by us in bundles
                            and the price may vary depending on the amount you purchase and where you’re purchasing.
                            As we feel it necessary, in our reasonable discretion (or as prescribed by law),
                            we may limit the total amount of Game Currency that may be purchased for any one game or that may be
                            held in your account in the aggregate. Additionally, price and availability of Game Currency
                            and Virtual Goods are subject to change. We may restrict how much Game Currency you can purchase
                            and/or use based on your location as we have different approaches in different locations.
                        </p>
                        <p className="ql-align-justify">
                            You should verify that the proper amount of Game Currency has been added to or deducted
                            from your account during any given transaction. Please notify us immediately
                            if you believe that a mistake has been made with respect to your Game Currency balance.
                            We’ll investigate your claim, and in doing so, may request some additional info to verify it.
                        </p>

                        <h2 id="section6">6. Unsolicited Idea Submission Policy</h2>
                        <h4>6.1. Can I submit ideas for improving the Game to Maethril?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Please don’t. If you do anyways, we’ll have the absolute right to use it forever.
                        </p>
                        <p className="ql-align-justify">
                            We value your feedback on the Maethril Services, but please don’t submit any creative ideas,
                            suggestions or materials to us (collectively, <b>“Unsolicited Ideas”</b>). We may freely use
                            any Unsolicited Ideas you provide. This policy is aimed at avoiding potential misunderstandings
                            or disputes when the Maethril Services might seem similar to Unsolicited Ideas that people submit.
                        </p>
                        <p className="ql-align-justify">
                            You may not have any legal rights in the Unsolicited Ideas you insist on sending to us,
                            but if you do, we will be allowed to use all or some of your Unsolicited Ideas
                            for any reason we choose and without any payment to you.
                        </p>
                        <p className="ql-align-justify">
                            In legal terms, this means that if you submit Unsolicited Ideas to us,
                            then you grant us a worldwide, perpetual, irrevocable, sublicensable,
                            transferable, assignable, non-exclusive, and royalty-free right and license to use,
                            reproduce, distribute, adapt, modify, translate, create derivative works based upon,
                            publicly perform, publicly display, digitally perform, make, have made, sell, offer for sale,
                            and import your Unsolicited Ideas, including all copyrights, trademarks, trade secrets,
                            patents, designs, industrial rights, and all other intellectual and proprietary rights related
                            to them, in any media now known or in the future developed, for any purpose whatsoever, commercial
                            or otherwise, including giving the Unsolicited Ideas to others, without any compensation to you.
                            To the extent necessary, you agree that you undertake to execute and deliver
                            any and all documents and perform any and all actions necessary or desirable to ensure that
                            the rights to use the Unsolicited Ideas granted to us as specified above are valid, effective,
                            and enforceable and you waive and agree never to assert those rights to the maximum extent permitted
                            by the laws of your jurisdiction.
                        </p>

                        <h2 id="section7">7. User Rules</h2>
                        <h4>7.1. Can I troll, flame, threaten or harass people while using the Maethril Services?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> No. If you do, we might take action such as banning your account.
                        </p>
                        <p className="ql-align-justify">
                            While using the Maethril Services, you must comply with all laws, rules and regulations
                            in the jurisdiction in which you reside. You must also comply with the acceptable use
                            and behavioral policies that we publish from time to time on our websites, apps and games
                            and the behavioral rules listed below (collectively the <b>“User Rules”</b>). The User Rules
                            posted to our websites, apps and games or set out in this section are not meant to be exhaustive,
                            and we reserve the right to modify them, as well as take appropriate disciplinary measures including
                            temporary bans, account suspension or termination and deletion to protect the integrity and spirit
                            of the Maethril Services, regardless of whether a specific behavior is listed in the User Rules
                            as inappropriate.
                        </p>
                        <p className="ql-align-justify">
                            The following are examples of behavior that warrant disciplinary measures:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                Impersonating any person, business or entity, including an employee of Maethril,
                                or communicating in any way that makes it appear that the communication originates from Maethril;
                            </li>
                            <li>
                                Publicly posting identifying info about yourself, Maethril employees, or other players;
                            </li>
                            <li>
                                Harassing, stalking or threatening other players or Maethril employees;
                            </li>
                            <li>
                                Removing, altering or concealing any copyright, trademark, patent or other proprietary rights
                                notice of Maethril contained within the Maethril Services. You also may not transmit content
                                that violates or infringes the rights of others, including patent, trademark, trade secret,
                                copyright, publicity, personal rights or other rights;
                            </li>
                            <li>
                                Transmitting or communicating any content which we reasonably believe to be offensive to players,
                                including language that is unlawful, harmful, threatening, abusive, harassing, defamatory,
                                vulgar, obscene, sexually explicit, or racially, ethically, or otherwise objectionable;
                            </li>
                            <li>
                                Transmitting or facilitating the transmission of any content that contains a virus,
                                corrupted data, trojan horse, bot keystroke logger, worm, time bomb, cancelbot or other
                                computer programming routines that are intended to and/or actually damage, detrimentally
                                interfere with, surreptitiously intercept or mine, scrape or expropriate any system,
                                data or personal info;
                            </li>
                            <li>
                                Avoiding, bypassing, removing, deactivating, impairing, descrambling or otherwise
                                circumventing any technological measure implemented by Maethril or any third party to protect
                                the Maethril Services;
                            </li>
                            <li>
                                Spamming chat, whether for personal or commercial purposes, by disrupting the flow
                                of conversation with repeated postings;
                            </li>
                            <li>
                                Participating in any action which we reasonably believe does or may defraud any other player,
                                including by scamming or social engineering;
                            </li>
                            <li>
                                Using any unauthorized third party programs, including mods, hacks, cheats, scripts, bots,
                                trainers and automation programs that interact with the Maethril Services in any way,
                                for any purpose, including any unauthorized third party programs that intercept, emulate,
                                or redirect any communication relating to the Maethril Services and any unauthorized
                                third party programs that collect info about the Maethril Services by reading areas of memory
                                used by the Maethril Services to store info;
                            </li>
                            <li>
                                Accessing or attempting to access areas of the Maethril Services
                                that are not made available to the public;
                            </li>
                            <li>
                                Selecting an account or user name that is falsely indicative of an association with Maethril,
                                contains personally identifying info, or that is offensive, defamatory, vulgar, obscene,
                                sexually explicit, racially, ethnically, or otherwise objectionable. You may not use a misspelling
                                or an alternative spelling to circumvent this restriction on name choices, like user names.
                                Maethril may modify any name which Maethril reasonably believes violates this provision,
                                without notification to you, and may take further disciplinary measures, including
                                account termination for repeated violations;
                            </li>
                            <li>
                                Non-participation, logging out or exiting a game during live game-play.
                                Maethril may track this data over time and issue a temporary ban when a player is determined
                                to have left mid-game too many times. The length of the temporary ban will increase over time
                                if a particular account continues to leave live game play;
                            </li>
                            <li>
                                Playing on another person’s account or otherwise engaging in activity intended to “boost”
                                an account’s status or rank;
                            </li>
                            <li>
                                Inducing or encouraging others to breach the User Rules or these Terms;
                            </li>
                            <li>
                                Using the Maethril Services for any business purpose without our express written consent
                                or after we’ve asked you to stop using the Maethril Services.
                            </li>
                        </ul>

                        <h2 id="section8">8. User Generated Content</h2>
                        <h4>8.1 Am I responsible for the content that I post on/in the Maethril Services?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Yes, and we can use what you post.
                        </p>
                        <p className="ql-align-justify">
                            Apart from Unsolicited Ideas (which we address separately,
                            in <a href="#section6">Section 6</a>, above),
                            you’re also responsible for any other communications, user names, images, sounds,
                            or other material and info that you create, upload, use or transmit with or through
                            the Maethril Services (<b>“Your Content”</b>).
                        </p>
                        <p className="ql-align-justify">
                            You should upload or transmit Your Content only if you agree that:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                You grant us, from the time of uploading or transmission of Your Content,
                                a worldwide, perpetual, irrevocable, sublicensable, non-exclusive
                                and royalty-free right and license to use, reproduce, distribute, adapt, modify,
                                translate, create derivative works based upon, publicly perform, publicly display,
                                digitally perform, make, have made, and import Your Content, including, all copyrights,
                                publicity rights, trademarks, trade secrets, patents, industrial rights
                                and all other intellectual and proprietary rights related to them, for the purpose of providing
                                the Maethril Services without any compensation to you;
                            </li>
                            <li>
                                You waive any moral rights you may have in Your Content with respect to our use of Your Content
                                to the maximum extent permitted by the laws of your jurisdiction. If local laws do not allow
                                for waiver of moral rights, instead you grant Maethril the right to use Your Content
                                and all elements of Your Content with or without your name or pseudonym,
                                as well as to freely edit Your Content.
                            </li>
                            <li>
                                You represent, warrant and agree that none of Your Content will be subject to any obligation,
                                whether of confidentiality, attribution or otherwise, on the part of Maethril
                                and Maethril won’t be liable for any use or disclosure of Your Content.
                            </li>
                            <li>
                                You further represent, warrant and agree that Your Content
                                shall not violate any third-party rights.
                            </li>
                        </ul>

                        <h2 id="section9">9. Monitoring</h2>
                        <h4>9.1 Does Maethril monitor me while using the Maethril Services?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Yes. Please read the Privacy Notice to learn more.
                        </p>
                        <p className="ql-align-justify">
                            We may actively monitor the use of the Maethril Services (but have no obligation to do so),
                            both on our own servers and on your computer or device, for a wide variety of different purposes,
                            including preventing cheating and hacking, reducing toxic player behavior,
                            and improving the Maethril Services. Please be sure to read
                            our <Link to="privacy-notice">Privacy Notice</Link> for important details
                            about how we obtain and process info in connection with your use of the Maethril Services.
                        </p>

                        <h2 id="section10">10. Updates & Modifications</h2>
                        <h4>10.1 Will these Terms change in the future?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Yes, please check periodically.
                        </p>
                        <p className="ql-align-justify">
                            We may (and probably will) create updated versions of these Terms in the future
                            as the Maethril Services and applicable laws evolve. When we do, we’ll inform
                            you of the new agreement which will supersede and replace these Terms.
                            You’ll be given an opportunity to review any new agreement we present to you
                            and decide whether you wish to agree to the revised terms. If you accept the new agreement,
                            you’ll be able to continue using the Maethril Services. If you decline the new agreement,
                            you won’t be able to use the Maethril Services anymore.
                        </p>
                        <h4>10.2 Will the Maethril Services stay the same?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> No. We try to improve and evolve to provide more safe,
                            and secure services to users.
                        </p>
                        <p className="ql-align-justify">
                            In an effort to constantly improve the Maethril Services, evolve our games
                            and keep the Maethril Services, safe, fun, and secure, you agree that we may change, modify,
                            update, suspend, “nerf,” or restrict your access to any features or parts of the Maethril Services,
                            including Virtual Goods (e.g., we might change some features of Virtual Goods for regulatory
                            or legal reasons or to improve the game experience), and may require that you download
                            and install updates to any software required to support the Maethril Services, at any time
                            without liability to you. You also understand and agree that any such changes or updates
                            to the Maethril Services might change the system specifications necessary to play our games,
                            and in such a case, you, and not Maethril, are responsible for purchasing any necessary
                            additional software or hardware in order to access and play our games. You also understand
                            and agree that we may use background patching to automatically update our games and software
                            with or without notice to you.
                        </p>

                        <h2 id="section11">11. Boards & Links</h2>
                        <h4>11.1 Can I use the message boards?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> So long as you have a valid account and act maturely.
                        </p>
                        <p className="ql-align-justify">
                            If you have a valid, active account, you may use the boards section of our website or
                            apps (the <b>“Boards”</b>) provided that you abide by the <a href="#section7">User Rules</a> and
                            any other posted rules. We may revoke your access to the Boards at any time if we believe
                            you have breached the User Rules. If you think we have made a mistake, please Contact Us
                            with details and we will review your case though your account may be suspended during our review.
                            Use the Boards at your own risk. We reserve the right, but have no obligation, to monitor
                            and police the Boards. We aren’t responsible for the content on the Boards. Except in cases
                            of our gross negligence or willful misconduct, we will have no liability to you for any harm
                            you may experience by using the Boards.
                        </p>
                        <h4>11.2 What about links in the Maethril Services? Are those Safe?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> You might find something dangerous. Proceed with caution.
                        </p>
                        <p className="ql-align-justify">
                            The Maethril Services may contain links to websites operated by other parties.
                            Again, we do not own or operate those other websites. We provide these links to you
                            as a convenience, or other users might be posting these links as user-provided content.
                            Use these links and the corresponding external websites at your own risk. We don’t control
                            the linked sites, and we’re not responsible for the content available there. Such links
                            don’t imply our endorsement of info or material on any other site, and we disclaim
                            all liability with regard to your access to and use of such linked websites.
                        </p>

                        <h2 id="section12">12. Warranty Disclaimer</h2>
                        <h4>12.1 Does MAethril make any warranties about the Maethril Services?</h4>
                        <p className="ql-align-justify">
                            <b>
                                You assume all responsibility for the installation and use of, and results obtained
                                from the Maethril Services (no matter where you are based).
                            </b>
                        </p>
                        <p className="ql-align-justify">
                            Also, if you are based in <b>North, Central or South America:</b>
                        </p>
                        <p className="ql-align-justify">
                            <b>
                                The Maethril Services are provided to you on an “as is” and “as available” basis
                                without warranties or representations of any kind, express or implied.
                                You assume all responsibility for your use of the Maethril Services. To the fullest
                                extent permitted by applicable law, Maethril disclaims all warranties, express or implied,
                                which might apply to the Maethril Services, including implied warranties of title,
                                non-infringement, merchantability, fitness for a particular purpose, any warranties
                                that may arise from course of dealing, course of performance or usage of trade,
                                and any warranties as to the accuracy, reliability or quality of any content or info
                                contained within the Maethril Services.
                            </b>
                        </p>

                        <h2 id="section13">13. Indemnification</h2>
                        <h4>
                            13.1 If someone sues Maethril based on my use or misuse of  the Maethril Services,
                            do I have to pay for Maethril’s legal expenses?
                        </h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Yes.
                        </p>
                        <p className="ql-align-justify">
                            To the extent permitted by law, except in cases involving our gross negligence
                            or willful misconduct, you hereby agree to indemnify, defend and hold harmless Maethril
                            and its subsidiaries, affiliates, officers and directors from and against any and all claims,
                            lawsuits, losses, liabilities and costs that arise or result from your misuse
                            of the Maethril Services, any violation by you of any of the provisions of these Terms
                            (for example, if you use any hacks, cheats or bots with the Maethril Services or you infringe
                            our IP rights), or any infringement by you of any third party’s rights. Maethril Games reserves
                            the right, at its own expense and in its sole and absolute discretion, to assume
                            the exclusive defense and control of any matter otherwise subject to indemnification
                            by you, in which event you’ll cooperate with Maethril in asserting any available defenses.
                        </p>
                        <p className="ql-align-justify">
                            For example, if you use the Maethril Services as part of a business operation
                            (something you’re not allowed to do) and we get sued because of something
                            you do in breach of these Terms, you’ll have to defend us in court
                            and pay for any damages we incur.
                        </p>

                        <h2 id="section14">14. Limitation of Liability</h2>
                        <h4>14.1 Is there a limit on Maethril’s potential liability under these Terms?</h4>
                        <p className="ql-align-justify">
                            <b>
                                To the extent permitted by law, our total liability to you
                                (whether for breach of this contract, negligence or for any other reason whatever)
                                for any loss, harm or damage suffered by you in connection with your downloading,
                                use and/or access of the Maethril Services is limited to the total amounts
                                paid by you to Maethril during the six (6) months immediately prior
                                to the time your cause of action first arose.
                            </b>
                        </p>
                        <p className="ql-align-justify">
                            <b>
                                We only make the Maethril Services available for domestic and private use.
                                We are not liable for business losses (such as any loss of profit, loss of business,
                                business interruption, loss of goodwill or loss of business opportunity).
                                We also won’t have responsibility for any damage which arises because you failed to install
                                any update when it is made available by Maethril or where you fail to maintain the minimum
                                system specifications required. Separately, we are not responsible for:
                                (i) any loss that was not foreseeable at the time you entered into these Terms; or
                                (ii) any loss that we both knew might happen only because of your special circumstances
                                existing at the time you entered into these Terms (including all indirect damages).
                            </b>
                        </p>
                        <p className="ql-align-justify">
                            <b>
                                Despite anything else we say in these Terms,
                                WE DO NOT EXCLUDE OR LIMIT IN ANY WAY OUR LIABILITY TO YOU WHERE
                                 IT WOULD BE UNLAWFUL TO DO SO,
                                including our liability for:
                                (i) death or personal injury arising from our negligence;
                                (ii) our fraud or fraudulent misrepresentation;
                                (iii) willful or grossly negligent behavior;
                                (iv) for damage arising from a negligent breach of an obligation
                                that is essential for the performance of the contract by Maethril (“Cardinal Duties”)
                                to the extent that is typical and foreseeable;
                                (v) for any guarantee given by us to you; or
                                (vi) for any liability under a jurisdiction’s applicable product liability legislation.
                            </b>
                        </p>
                        <p className="ql-align-justify">
                            <b>
                                You may also have specific rights under local law in addition to those set out above.
                            </b>
                        </p>

                        <h2 id="section15">15. Governing Law</h2>
                        <h4>15.1 What law applies to these Terms or if we get into a dispute?</h4>
                        <p className="ql-align-justify">
                            Irrespective of this choice-of-law, you may have the benefit of other
                            or additional mandatory rights or remedies provided by local law in addition
                            to those set out in these Terms. Nothing in these Terms limits or affects those rights.
                        </p>

                        <h2 id="section16">16. Additional Terms Applicable To Maethril Services</h2>
                        <h4>16.1 General Terms</h4>
                        <p className="ql-align-justify">
                            You may access Maethril Services we make available from certain authorized
                            third party stores or platforms (an authorized third party provider
                            of any store, game system, console or platform each
                            a <b>“Third Party Platform</b>”), only if you agree that:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                These Terms are an agreement between us and you, and not between you
                                and the Third Party Platform. As between Maethril and the Third Party Platform,
                                Maethril is solely responsible for any Maethril Services.
                            </li>
                            <li>
                                The Third Party Platform has no obligation to provide any maintenance
                                and support services for the Maethril Services.
                            </li>
                            <li>
                                The Third Party Platform is not responsible for addressing
                                any claims you have relating to the Maethril Services or your possession
                                and use of the Maethril Service.
                            </li>
                            <li>
                                If a third party claims any of the Maethril Services infringes
                                another party's intellectual property rights, as between
                                the Third Party Platform and Maethril, the Third Party Platform
                                will not be responsible for the investigation, defense, settlement and discharge
                                of any such claim.
                            </li>
                            <li>
                                The Third Party Platform and its subsidiaries are third-party beneficiaries
                                of these Terms as they relate to your license to the Maethril Services.
                                Upon your acceptance of these Terms, the Third Party Platform will have the right
                                (and will be deemed to have accepted the right) to enforce
                                this <a href="#section16">Section 16</a> as related
                                to your license of the Maethril Services against you as a third-party beneficiary.
                            </li>
                            <li>
                                You must also comply with all applicable third-party terms of service
                                and licenses when accessing or using the Third Party Platform including
                                any additional terms that apply when you download, stream, access or use any Maethril game,
                                Virtual Content or other Maethril Services.
                            </li>
                        </ul>

                        <h2 id="section17">17. Miscellaneous</h2>
                        <h4>17.1 What can I do if I have questions about the Maethril Services?</h4>
                        <p className="ql-align-justify">
                            If you have any questions concerning the Maethril Services, or if you would like to
                            contact us for any other reason, please contact Maethril support
                            at <a href="mailto:support@maethril.net">support@maethril.net</a>.
                        </p>
                        <h4>17.2 Can either party assign these Terms?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> No. Just us.
                        </p>
                        <p className="ql-align-justify">
                            Maethril may assign its rights under these Terms, in whole or in part, to any person
                            or entity at any time with or without your consent. You may not assign your rights
                            under these Terms without Maethril’ prior written consent, and any unauthorized assignment
                            by you shall be null and void.
                        </p>
                        <h4>17.3. Is this our entire agreement?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Yes, including those other important policies we talked about earlier.
                        </p>
                        <p className="ql-align-justify">
                            These Terms represent the complete agreement between you and Maethril concerning
                            the Maethril Services, and supersede any prior or contemporaneous agreements between
                            you and Maethril Games. These Terms shall coexist with, and shall not supersede
                            any other Maethril Games policies referenced in these Terms. You and Maethril agree
                            that we each have not relied upon, and have no remedies in respect of, any terms, conditions,
                            representations, warranties or similar that are not expressly set out in these Terms.
                        </p>
                        <h4>17.4 What happens if a natural disaster interferes with the Game?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Maethril isn’t liable.
                        </p>
                        <p className="ql-align-justify">
                            Maethril shall not be liable for any delay or failure to perform,
                            including any failure to perform under these Terms due to unforeseen circumstances
                            or cause beyond Maethril’ control such as: (a) hacking, cyber-attacks, data corruption/loss
                            (where we have taken the steps that would reasonably be taken by companies
                            like us to avoid this event occurring); or (b) acts of god, war, terrorism, bomb-threats,
                            riots, embargoes, acts of civil or military authorities, fire, floods, accidents,
                            strikes, or shortages of transportation facilities, fuel, energy, labor or materials.
                        </p>
                        <h4>17.5 Do these Terms mean we’re partners?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> Not in a legal sense, no.
                        </p>
                        <p className="ql-align-justify">
                            You agree that no joint venture, partnership, employment, or agency relationship
                            exists between you and Maethril as a result of these Terms
                            or your use of the Maethril Services.
                        </p>
                        <h4>
                            17.6 If I breach these Terms and Maethril does nothing for a long time,
                            does that mean I’m off the hook?
                        </h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> No. We reserve all of our rights.
                        </p>
                        <p className="ql-align-justify">
                            Maethril’ failure to enforce any provision of these Terms shall in no way
                            be construed to be a present or future waiver of such provision, nor in any way
                            affect the right of any party to enforce each and every such provision after that.
                            Any waiver by Maethril of any provision, condition or requirement of these Terms shall
                            not constitute a waiver of any future obligation to comply with such provision,
                            condition or requirement.
                        </p>
                        <h4>17.7 What remedies are available to Maethril if I breach these Terms?</h4>
                        <p className="ql-align-justify">
                            You agree that Maethril would suffer irreparable harm if a breach of certain of these Terms
                            (such as <a href="#section3">Section 3.2</a>) was not specifically enforced
                            and that damages would not be an adequate remedy for Maethril as a result.
                            We may rely upon this Section to ask a court for injunctive relief or specific performance.
                        </p>
                        <h4>17.8 If part of these Terms is invalidated, what happens to the rest?</h4>
                        <p className="ql-align-justify">
                            Except as otherwise provided in these Terms, if any provision of these Terms
                            is held to be invalid or unenforceable for any reason, such provision
                            shall be considered severed from these Terms and the remainder of these Terms
                            shall continue in full force and effect.
                        </p>
                        <h4>17.9 How long does this agreement last?</h4>
                        <p className="ql-align-justify">
                            <b>Summary:</b> For as long as you use the Maethril Services
                            or until your account is terminated.
                        </p>
                        <p className="ql-align-justify">
                            These Terms begins on the date you first accept them and last for as long as you use
                            or maintain an account on the Maethril Services. However,
                            Sections <a href="#section2">2.3</a>, <a href="#section3">3.2</a>, <a href="#section4">4.3</a>, <a href="#section4">4.4</a>, <a href="#section4">4.5</a>, <a href="#section5">5.1</a>, <a href="#section6">6</a>, <a href="#section8">8</a>, and <a href="#section13">13-16</a> inclusive,
                            shall survive the termination of these Terms.
                        </p>
                        <h4>17.10 Where we say “including” in these Terms, is that language limiting?</h4>
                        <p className="ql-align-justify">
                            Whenever we use “including” in these Terms, we mean “including without limitation.”
                        </p>
                        <p className="ql-align-justify">
                            <b>
                                YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS,
                                AND THAT BY SELECTING THE “ACCEPT” BUTTON BELOW OR OTHERWISE USING OR ACCESSING
                                THE MAETHRIL SERVICES, YOU ARE AGREEING TO BE BOUND BY THE TERMS AND CONDITIONS
                                SET OUT IN THESE TERMS.
                            </b>
                        </p>
                    </section>
                    <div className={styles.readMoreContainer}>
                        <Link className={styles.readMoreLink} to='/'>
                            <FiChevronLeft />
                        </Link>
                    </div>
                    <div className={styles.newsletterContainer}>
                    </div>
                </div>
            </article>
            {
                cookies["maethril_consent"] &&
                    <CookieSettings open={cookiesOpen} setClose={() => setCookiesOpen(false)} />
            }
        </div>
    );
}

export default Terms;
