import React, { KeyboardEventHandler, useCallback, useEffect, useState } from 'react';
import configData from '../config.json';
import styles from './Home.module.css';
import axios from 'axios';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { Helmet } from 'react-helmet';
import debounce from 'lodash.debounce';

import { FiSearch } from "react-icons/fi";
import BlogCard from '../components/BlogCard';
import SkeletonBlogCard from '../components/SkeletonBlogCard';
import TagFilter from '../components/TagFilter';
import { ISearchBlog, IPagination, ITagFilter, ITagDTO } from '../types/Pagination';
import { IBlogCard } from '../types/Blog';

const blogsPerPage = 5;
const debounceTime = 250;

const Home: React.FC = () => {
    const [blogs, setBlogs] = useState<IPagination<IBlogCard>>({totalCount: 0, items: []});
    const [loadingBlogs, setLoadingBlogs] = useState<boolean>(true);
    useBottomScrollListener(onBottom, { offset: 50, debounce: 500});

    const [tags, setTags] = useState<ITagFilter[]>([]);
    const [loadingTags, setLoadingTags] = useState<boolean>(true);

    const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
    const [lastSearch, setLastSearch] = useState<string>("");
    const [search, setSearch] = useState<string>("");

    const debouncedFetch = useCallback(
        debounce((search: ISearchBlog) => getBlogs(search), debounceTime), []);

    const skeletonItems = [0, 1, 2, 3, 4];

    const getBlogs = async (search: ISearchBlog) => {
        const resetBlogs = search.index === 0 && blogs.items.length > 0;

        axios({
            method: 'POST',
            url: configData.BLOGS_URL,
            data: {
                index: search.index,
                amount: search.amount,
                searchValue: search.searchValue,
                tags: search.tags
            }
        })
            .then(response => {
                setBlogs({
                    totalCount: response.data["totalCount"],
                    items: resetBlogs ? response.data["items"] : blogs.items.concat(response.data["items"])
                });
            })
            .catch(error => {})
            .finally(() => {
                setLoadingBlogs(false)
                setLastSearch(search.searchValue);
            });
    }

    useEffect(() => {
        getBlogs({
            index: 0,
            amount: blogsPerPage,
            searchValue: search,
            tags: tags.filter(obj => obj.selected).map(obj => obj.name)
        });
    }, []);

    function toggleSearchBarOn(): void {
        if(tags.length === 0)
        {
            axios.get<ITagDTO[]>(configData.TAGS_URL)
            .then(response => {
                let tags: ITagFilter[] = [];
                for(let i = 0; i < response.data.length; ++i)
                {
                    tags.push({
                        id: response.data[i].id,
                        name: response.data[i].name,
                        selected: false
                    });
                }

                setTags(tags)
            })
            .catch(error => console.log(error))
            .finally(() => setLoadingTags(false));
        }

        setShowSearchBar(true);
    }

    function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setSearch(e.target.value)
        debouncedFetch({
            index: 0,
            amount: blogsPerPage,
            searchValue: e.target.value,
            tags: tags.filter(obj => obj.selected).map(obj => obj.name)
        });
    }

    function handleSubmit(e: React.FormEvent): void {
        e.preventDefault();
       handleSearch();
    }

    function handleSearch(): void {
        if(search === lastSearch)
            return;

        debouncedFetch({
            index: 0,
            amount: blogsPerPage,
            searchValue: search,
            tags: tags.filter(obj => obj.selected).map(obj => obj.name)
        });
    }

    function handleTagClick(tag: ITagFilter): void {
        let newTags: ITagFilter[] = [];
        tags.forEach(obj => {
            let newTag: ITagFilter = {
                id: obj.id,
                name: obj.name,
                selected: obj.name === tag.name ? !obj.selected : obj.selected
            }
            newTags.push(newTag);
        });

        setTags(newTags);
        debouncedFetch({
            index: 0,
            amount: blogsPerPage,
            searchValue: search,
            tags: newTags.filter(obj => obj.selected).map(obj => obj.name)});
    }

    function onBottom(): void {
        if(blogs.items.length >= blogs.totalCount)
            return;

        getBlogs({index: blogs.items.length, amount: blogsPerPage, searchValue: search, tags: []});
    }

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Maethril Blog</title>
                <meta name="description" content="Maethril blogs about game development, tech & more." />
            </Helmet>
            {
                showSearchBar ?
                    <form className={styles.searchBarContainer} onSubmit={handleSubmit}>
                        <input className={styles.searchBar}
                               type="text"
                               placeholder="Search"
                               value={search}
                               onChange={e => handleSearchChange(e)} />
                        <div className={styles.tagsContainer}>
                            {
                                tags.map(obj =>
                                    <div className={styles.tagContainer}>
                                        <TagFilter key={obj.id}
                                                   tag={obj}
                                                   onClick={handleTagClick} />
                                        { obj.id !== tags[tags.length - 1].id && <span className={styles.tagSpacer} /> }
                                    </div>
                                )
                            }
                        </div>
                    </form> :
                    <FiSearch className={styles.searchIcon} onClick={toggleSearchBarOn} />
            }
            {
                loadingBlogs ?
                <div>
                    { skeletonItems.map(obj => <SkeletonBlogCard key={obj} />) }
                </div> : (
                    blogs.items.length > 0 ?
                    <div>
                        { blogs.items.map(blog => <BlogCard key={blog.id} {...blog} />) }
                        { blogs.items.length < blogs.totalCount && <SkeletonBlogCard /> }
                    </div> :
                    <div>
                        <p className={styles.noBlogs}>No blogs found</p>
                    </div>
                )
            }
        </div>
    );
}

export default Home;
