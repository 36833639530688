import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';
import styles from './Blog.module.css';
import { FiChevronLeft } from 'react-icons/fi';
import { useCookies } from 'react-cookie';
import CookieConsent from '../components/Cookies/CookieConsent';
import CookieSettings from '../components/Cookies/CookieSettings';

interface IProps extends RouteComponentProps<{ id: string }> {}

const CookiePolicy: React.FC<IProps> = () => {
    const [cookies, setCookie] = useCookies(['maethril_consent']);
    const [cookiesOpen, setCookiesOpen] = useState<boolean>(false);

    return (
        <div className={styles.mainWrapper}>
            <article className={styles.mainContainer} itemScope itemType="http://schema.org/Article">
                <Helmet>
                    <title>Cookie Policy | Maethril</title>
                    <meta name="description" content="Cookie policy" />
                </Helmet>

                <meta itemProp="datePublished" content="Jun 1, 2021" />
                <meta itemProp="publisher" content="Maethril" />

                <div className={styles.contentContainer}>
                    <header className={styles.header}>
                        <h1 itemProp="name headline">Maethril's Cookie Policy</h1>
                        <div className={styles.headerInfo}>
                            {
                                <div className={styles.authorAvatarContainer}>
                                    <img className={styles.avatarImg}
                                        src="/media/Maethril-250x250.png"
                                        alt="Maethril" />
                                </div>
                            }

                            <div className={styles.info}>
                                <p className={styles.authorName} data-tip data-for="authorInfo">Maethril</p>
                                <p className={styles.lowPriorityInfo}>
                                    <span className={styles.lpLeft} />
                                    Jun 1, 2021
                                    <span className={styles.infoDivider}>·</span>
                                    12 min read
                                </p>
                            </div>
                        </div>
                    </header>
                    <section className={styles.content} itemProp="articleBody">
                        <p className="ql-align-justify">
                            Welcome to Maethril! Our cookie policy explains the types of cookies
                            and other similar tracking technologies that we
                            and our partners apply when you visit or use the Maethril services.
                        </p>
                        <p className="ql-align-justify">
                            When we say “Maethril” (or similar things like “Company”, “we”, “us” or “our”),
                            we’re referring to the Maethril entity or, where applicable, entities that control
                            and are responsible for your information.
                        </p>
                        <p className="ql-align-justify">
                            When we say “Maethril services” (or similar things like “services”, “our services”),
                            we mean any games, websites, applications, and services that link to this notice,
                            regardless of how you access or use them or engage with us, including through mobile devices
                            or offline such as by phone. Your use of the Maethril services is also subject
                            to the <Link to="terms-of-service">Terms of Service</Link> in your region
                            (including its provisions regarding limitations on damages, resolution of disputes,
                            and application of governing law). Please read this entire notice to make sure you understand it.
                            You should also read the <Link to="terms-of-service">Terms of Service</Link> carefully,
                            and make sure you understand and agree to them before using the Maethril services.
                        </p>
                        <p className="ql-align-justify">
                            Our cookie policy should be read in conjunction with
                            the <Link to="privacy-notice">Privacy Policy</Link> for
                            the Maethril services in your region. By using or accessing the Maethril services,
                            you’re consenting to the practices described below
                            (except to the extent you choose to disable them).
                        </p>

                        <h2>Table of Contents</h2>
                        <div className={styles.tableOfContents}>
                            <ul>
                                <li><h4><a href="#what-is-a-cookie">1. What Is A Cookie</a></h4></li>
                                <li><h4><a href="#what-cookies-does-the-riot-service-use">2. Why Are Cookies Used On The Maethril Services?</a></h4></li>
                                <li><h4><a href="#who-places-tracking-technologies-on-my-device">3. Who Places Cookies On My Device?</a></h4></li>
                                <li><h4><a href="#how-do-i-manage-tracking-technologies">4. How Can I Manage Cookies?</a></h4></li>
                                <li><h4><a href="#do-not-track-signals">5. Do-Not-Track Signals</a></h4></li>
                                <li><h4><a href="#policy-updates">6. Policy Updates</a></h4></li>
                                <li><h4><a href="#contact-us">7. Contact Us</a></h4></li>
                            </ul>
                        </div>

                        <h2 id="what-is-a-cookie">1. What Is A Cookie</h2>
                        <p className="ql-align-justify">
                            “Cookies” are small pieces of data (often text files) that are placed
                            on your computer or device by the Maethril services that you visit or our partners.
                            As a standard feature of major browser applications, cookies are widely used
                            to make online services work or operate more efficiently, as well as to provide info
                            to the providers of those online services about your browsing experiences. When you return
                            to those same online services again, your browser sends the previously stored cookies back
                            to the online service which allows it to recognise you and remember important info to make
                            your use of the online services more personalized or convenient, such as by remembering
                            your saved preferences.
                        </p>
                        <p className="ql-align-justify">
                            We and our partners also use other technologies,
                            including data stored on your web browser or device, identifiers associated with your device
                            and other software for similar purposes. This includes the following:
                        </p>
                        <ul className="ql-align-justify">
                            <li>
                                <strong>Pixel tags:</strong> Pixel tags (also called beacons or pixels) are small blocks
                                of code installed on (or called by) a webpage, email, app, or ads which can retrieve
                                certain info about your device and browser and how you interact with it. This includes,
                                for example, information about your device type, operating system, browser type and version,
                                website visited, time of visit, referring website, IP address, whether you’ve opened
                                an email or clicked on an ad, and other similar info, including the small text file
                                (the cookie) that uniquely identifies the device. Pixels provide the means
                                by which third parties can set and read browser cookies from a domain that they do not
                                themselves operate and collect info about visitors to that domain, typically
                                with the permission of the domain owner.
                            </li>
                            <li>
                                <strong>Local storage:</strong> This refers generally to other places on a browser
                                or device where info can be stored by websites, ads, or third parties
                                (such as HTML5 local storage and browser cache).
                            </li>
                            <li>
                                <strong>Software development kits:</strong> Software development kits (also called SDKs)
                                function like pixels and cookies, but operate in the mobile app context where pixels
                                and cookies cannot always function. Pieces of code (the SDK) are installed in the app
                                that allow us and our partners to collect certain info about your interaction with
                                the app and info about your device and network.
                            </li>
                            <li>
                                <strong>Flash cookies:</strong> This is a technology that can provide cookie-equivalent functionality
                                and is capable of storing information on your device. This information is often stored in
                                a different file to other cookies. This means it has to be managed and deleted in a different way.
                                We explain below how you can do this.
                            </li>
                            <li>
                                <strong>IP address:</strong> IP address is the short form for Internet Protocol address
                                which is assigned by internet access providers to every computer that is connected to the internet.
                                Website owners have access to IP addresses of their users. Using IP addresses,
                                we and our partners can identify the country, state and city from which a computer is connecting
                                to the Internet. IP addresses are, for example, used for IP geolocation purposes,
                                e.g. by Google Analytics.
                            </li>
                            <li>
                                <strong>Device identifiers:</strong> We may also collect your IP address,
                                unique device identifier or create a unique device fingerprint
                                (this is a unique combination of different information from your device so that we can single it out)
                                so we can identify your device. We may then use this in a similar way to cookies.
                            </li>
                        </ul>
                        <p className="ql-align-justify">
                            In this policy, we refer to all of these and similar technologies as “cookies.”
                            These technologies can be used to recognise when your device does things. For instance,
                            they may recognise when you visit pages on our or others’ sites, use parts of an app
                            or when you open emails we send you, or links in technologies.
                        </p>

                        <h2 id="what-cookies-does-the-riot-service-use">2. Why Are Cookies Used On The Maethril Services?</h2>
                        <p className="ql-align-justify">
                            We and our partners use cookies to automatically record some info
                            about how you use and navigate the Maethril services and to recognize use of the Maethril services.
                            This, in particular, helps to identify you, analyze, improve and secure the Maethril services
                            and to personalise content and features and tailor and measure ads, both on and off the Maethril services.
                        </p>
                        <p className="ql-align-justify">
                            Whilst the cookies used on the Maethril services change from time to time
                            as we improve and update the Maethril services, they generally fall into the below categories of use:
                        </p>
                        <h4>Essential Cookies</h4>
                        <p className="ql-align-justify">
                            <strong>Authentication:</strong> We use cookies to verify your account and determine
                            when you’re logged in so we can make it easier for you to access the Maethril services
                            and show you the appropriate information, experience and features. For example,
                            we use cookies to keep you logged in as you navigate between different pages on the Maethril service
                            or access secured portions of the Maethril services. Cookies also help us remember
                            your browser so you do not have to keep logging into the Maethril services.
                        </p>
                        <p className="ql-align-justify">
                            <strong>Security:</strong> We use cookies to provide necessary checks
                            and controls to protect both you and us. For example, they help us to authenticate your use,
                            prevent fraudulent use of access credentials maintain the confidentiality
                            of your data and to help us detect malicious activity and violation of our Terms of Service.
                        </p>
                        <h4>Functional Cookies</h4>
                        <p className="ql-align-justify">
                            <strong>Preferences and features:</strong>  We use cookies to enable the functionality
                            that helps us provide the Maethril services. For instance, we use cookies to help us
                            remember certain info about your choices, preferences, how far you are through
                            an order and what you’ve seen or done on the Maethril services to enhance your experience
                            on the Maethril services. They allow us to better personalise your visits
                            to the Maethril services by remembering things like your selected region, language, or display settings.
                            This helps us show you appropriate options and display customized content accordingly when you return
                            to the Maethril services. For example, by remembering your region and language, the Maethril services
                            may be able to provide you with local articles or schedules for upcoming events in your area,
                            and do so in your local language. We may also use cookies to capture your preferred audio and visual settings
                            (such as, text size, font, resolution, etc.), reference websites, the sequence of pages within
                            the Maethril services that you visit, and other similar details about your browsing behaviour
                            and choices about how the Maethril services behave or look. This helps us to, among other things,
                            monitor and improve the Maethril services, personalize your browsing experience and facilitate return visits.
                            Many of the features should still work without preference cookies. However, your experience
                            may be less functional and you may need to re-enter your preferences each time you visit.
                        </p>
                        <h4>Analytical Cookies</h4>
                        <p className="ql-align-justify">
                            <strong>Analytics and research:</strong>  We use cookies to collect info
                            about your interactions with the Maethril services which helps us to understand
                            and analyse how you use the Maethril services and improve them. For example,
                            they help us understand and analyse which pages are the most frequently visited,
                            identify when you may encounter difficulties and identify ways to improve the Maethril services.
                            In other words, they allow us to see and analyse overall patterns of usage on the Maethril services,
                            analyse general traffic and show the ways you interact with the Maethril services.
                            We may also engage partners from time-to-time to help us better understand and analyse how you interact
                            with the Maethril services, including by placing analytics and research cookies on our behalf.
                            For example, we use third-party analytics providers like Google Analytics to help evaluate
                            and report on use of the Maethril services. These companies may set and read their own tracking
                            technologies to gather info about your online activities across websites and services.
                        </p>
                        <h4>Advertising Cookies</h4>
                        <p className="ql-align-justify">
                            <strong>Advertising, insight and measurement:</strong>  We and our partners use cookies
                            to collect info about your visit to the Maethril services and your use of them to deliver ads
                            for businesses and other organisations that we or our partners think may be of interest to you,
                            both on and off the Maethril services. This is done based upon your usage of our and others’ sites.
                            For example, we or our partners may draw insights about you based upon what you use on the Maethril services
                            or viewed on other sites and use that to deliver an add considered relevant to you. These types of ads
                            are called “Interest-Based Advertising.” We and our partners also use cookies to measure the performance
                            of ad campaigns. For instance, we and our partners use cookies to count the number of times an ad is shown
                            and to measure how often people do things like click on or view ads both on and off the Maethril services.
                            We work with third-party partners that set their own cookies in this regard. For example, our advertising
                            partners may use cookies to help serve you ads better tailored to the interests you’ve shown by browsing
                            the Maethril services or other online services operated by third parties, determine whether you’ve seen
                            a particular advertisement before (to prevent showing you duplicate ones)
                            and for other lawful business purposes.
                        </p>
                        <p className="ql-align-justify">
                            We and our partners may link any of the information we collect through cookies
                            and other technologies (such as browser history):
                        </p>
                        <ul className="ql-align-justify">
                            <li>with information you provide;</li>
                            <li>
                                with information we obtain from other sources,
                                including from both online and offline data providers
                                (such as demographic data and interest based data);
                            </li>
                            <li>
                                where you agree to this in accordance with your privacy settings on social sites,
                                with information we obtain from social sites
                                (such as with respect to your usage of it or your posts, messages or comments on it).
                            </li>
                        </ul>

                        <h2 id="who-places-tracking-technologies-on-my-device">3. Who Places Cookies On My Device?</h2>
                        <p className="ql-align-justify">
                            Cookies may be placed on your device by Maethril as the operator of the Maethril services.
                            These cookies are called “first party” cookies. Some cookies may also be placed on your device,
                            when using the Maethril services, by one of our partners. These cookies are called “third party”
                            cookies and are used to provide services to us and others. Therefore, some cookies that have been set
                            on the Maethril services are not related to Maethril. When you visit a Maethril service with content
                            embedded from, say Google’s DoubleClick, Nanigans.com, Ad4game.com or any other companies,
                            these partners may set their own cookies on your web browser.
                            These record your visit to the Maethril services and this info is shared with
                            other organisations such as advertisers.
                        </p>
                        <p className="ql-align-justify">
                            We do not control third party cookies and cannot access them due to the way that cookies work,
                            as cookies can only be accessed by the party who originally set them.
                            Please read our partners privacy policies to ensure you are comfortable with how they use cookies.
                            To learn more about companies that provided “third party” cookies on Maethril services
                            and how they use information about you,
                            click <a className={styles.clickable} onClick={() => setCookiesOpen(true)}>here</a>.
                        </p>
                        <p className="ql-align-justify">
                            When you visit a page with content embedded from, for example, YouTube or Facebook or social media widgets
                            like the Facebook like button, these service providers may use their own cookies or similar technologies.
                            We do not control them.
                        </p>

                        <h2 id="how-do-i-manage-tracking-technologies">4. How Can I Manage Cookies?</h2>
                        <p className="ql-align-justify">
                            Where the relevant Maethril service has the Cookie Consent Tool incorporated,
                            you can use it to refuse or accept cookies from the Maethril services at any time.
                        </p>
                        <p className="ql-align-justify">
                            You can find out more about how to see what cookies have been set and how to manage
                            and disable them at <a href="http://www.allaboutcookies.org/manage-cookies/index.html">All About Cookies</a>.
                            You can also find info about how to enable or disable cookies
                            on your Internet browser provider’s website via your help screen.
                        </p>
                        <p className="ql-align-justify">
                            To learn more about how advertisers generally use cookies and the choices they offer,
                            you can review the following resources:
                        </p>
                        <ul>
                            <li><a href="http://optout.aboutads.info/?c=2#!/">Digital Advertising Alliance</a></li>
                            <li><a href="https://youradchoices.ca/">Digital Advertising Alliance of Canada</a></li>
                            <li><a href="http://www.youronlinechoices.eu/">European Interactive Digital Advertising Alliance</a></li>
                            <li><a href="http://optout.networkadvertising.org/#!/">Network Advertising Alliance</a></li>
                        </ul>
                        <p className="ql-align-justify">
                            If you want to clear all cookies left behind by online services including
                            the Maethril services you have visited, here are links where you can download programs
                            that clean out tracking
                            cookies: <a href="https://www.adaware.com/">Lavasoft</a> and <a href="http://www.spybot.info/en/download/index.html">Spybot.</a>
                        </p>
                        <p className="ql-align-justify">
                            Cookie management tools provided by your browser will not remove Flash Cookies.
                            To learn how to manage privacy and storage settings for Flash Cookies, please consult
                            the instructions provided by Adobe <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">here</a>.
                        </p>
                        <p className="ql-align-justify">
                            When you install our apps, SDKs are installed. You cannot deactivate an SDK. Consequently,
                            do not install our apps if you are not happy with the use of SDKs.
                        </p>
                        <p className="ql-align-justify">
                            We do not control third party cookies and cannot access them due to the way that cookies work,
                            as cookies can only be accessed by the party who originally set them. You should check
                            the third party websites for more info about these cookies. To learn more about companies
                            that provided “third party” cookies through Maethril services and how to manage their cookies,
                            click <a className={styles.clickable} onClick={() => setCookiesOpen(true)}>here</a>.
                        </p>
                        <p className="ql-align-justify">
                            Please note that opting-out of interest-based advertising won’t necessarily entirely prevent
                            info from being collected automatically on the Maethril services, and it doesn’t mean you’ll
                            receive fewer ads or stop them from being displayed. Instead, the ads you do receive won’t be
                            interest-based any longer and, therefore, might be less relevant to your interests.
                        </p>
                        <p className="ql-align-justify">
                            Please be aware that if cookies are disabled, not all features
                            of the Maethril services may operate as intended.
                        </p>
                        <p className="ql-align-justify">
                            To learn more about opting out of Google Analytics, which uses cookies as well as your IP address,
                            click <a href="https://tools.google.com/dlpage/gaoptout">here</a>.

                        </p>

                        <h2 id="do-not-track-signals">5. Do-Not-Track Signals</h2>
                        <p className="ql-align-justify">
                            Our services don’t respond to Internet browser “Do Not Track” signals.
                            Some newer Internet browsers incorporate DNT features. If activated,
                            these features often send a signal to the services you visit indicating that you don’t want
                            to be tracked in some way. Those services (or content on them placed by third parties)
                            may continue to engage in activities you may view as tracking even though you’ve expressed
                            this preference, depending on the service’s privacy practices.
                        </p>
                        <p className="ql-align-justify">
                            Because there is not yet a common understanding of how to interpret the DNT signal,
                            Maethril does not currently respond to browser DNT signals on the Maethril services.
                        </p>

                        <h2 id="policy-updates">6. Policy Updates</h2>
                        <p className="ql-align-justify">
                            We may update our policy from time to time to reflect changes in technology, legislation,
                            our operations, or as we otherwise determine is necessary or appropriate. Any such changes
                            will become effective when we make the revised policy available on or through the Maethril services.
                            We encourage you to review the date legend above frequently to ensure you are aware of when
                            our policy was last revised, and to help you better understand the nature of your interactions
                            with the Maethril services.
                        </p>

                        <h2 id="contact-us">7. Contact Us</h2>
                        <p className="ql-align-justify">
                            If you have any questions about this notice or our privacy practises, please send them to us via email
                            at <a href="mailto:privacy@maethril.net">privacy@maethril.net.</a>
                        </p>
                    </section>
                    <div className={styles.readMoreContainer}>
                        <Link className={styles.readMoreLink} to='/'>
                            <FiChevronLeft />
                        </Link>
                    </div>
                    <div className={styles.newsletterContainer}>
                    </div>
                </div>
            </article>
            {
                cookies["maethril_consent"] &&
                    <CookieSettings open={cookiesOpen} setClose={() => setCookiesOpen(false)} />
            }
        </div>
    );
}

export default CookiePolicy;
