import React  from 'react';
import { FiCoffee, FiStar, FiTerminal, FiTool } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { IBlogCard } from '../types/Blog';
import styles from './BlogCard.module.css';

const BlogCard: React.FC<IBlogCard> = (props: IBlogCard) => {
    function truncate(str: string, n: number) {
        return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
    }

    function getTagIcon(tag: string): JSX.Element {
        switch(tag){
            case "Major":
                return <FiStar key={props.id + 0} className={styles.tagIcon} />
            case "General":
                return <FiCoffee key={props.id + 1}  className={styles.tagIcon} />
            case "Tech":
                return <FiTool key={props.id + 2}  className={styles.tagIcon} />
            case "Dev Update":
                return <FiTerminal key={props.id + 3}  className={styles.tagIcon} />
        }

        return <></>
    }

    return (
        <Link to={`/blog/${props.id}`} className={styles.cardContainer}
            draggable="false" onDragStart={() => {return false}}>
            <div className={styles.contentContainer}>
                <h2 className={styles.title}>{truncate(props.title, 60)}</h2>
                <p>{props.description}</p>
                <div className={styles.bottom}>
                    { props.publishedDate && <p className={styles.publishedDate}>{props.publishedDate}</p> }
                    <span>{props.estMinToRead} min read</span>
                </div>
                <div className={styles.tagsContainer}>
                    { props.tags.map(tag => getTagIcon(tag)) }
                </div>
            </div>
            {
                props.thumbnail &&
                <div className={styles.thumbnailContainer}>
                    <img className={styles.thumbnail} src={props.thumbnail} alt={props.title}
                        draggable="false" onDragStart={() => {return false}}/>
                </div>
            }
        </Link>
    );
}

export default BlogCard;
