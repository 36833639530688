import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Switch from "react-switch";
import { useCookies } from 'react-cookie';

import styles from './CookieConsent.module.css';

interface ICookieConsent
{
    showMarketing?: boolean;
    showPersonalization?: boolean;
    showAnalytics?: boolean
}

const CookieConsent: React.FC<ICookieConsent> = (props: ICookieConsent) => {
    const [cookies, setCookie] = useCookies(['maethril_consent']);

    const [marketing, setMarketing] = useState<boolean>(false);
    const [personalization, setPersonalization] = useState<boolean>(false);
    const [analytics, setAnalytics] = useState<boolean>(true);

    function handleAcceptAll() {
        let cookie = {
            marketing: true,
            personalization: true,
            analytics: true
        }

        let expiration = new Date();
        expiration.setDate(expiration.getDate() + 365);

        setCookie("maethril_consent", cookie, {
            path: "/",
            secure: true,
            sameSite: 'lax',
            expires: expiration
        });
    }

    function handleSave() {
        let cookie = {
            marketing: marketing,
            personalization: personalization,
            analytics: analytics
        }

        let expiration = new Date();
        expiration.setDate(expiration.getDate() + 365);

        setCookie("maethril_consent", cookie, {
            path: "/",
            secure: true,
            sameSite: 'lax',
            expires: expiration
        });
    }

    return (
        <div className={styles.container}>
            <div className={styles.infoContainer}>
                <span className={styles.text}>
                    This website stores data such as cookies to enable essential site functionality,
                    as well as marketing, personalization, and analytics.
                    You may change your settings at any time or accept the default settings.
                    <br />
                    <span className={styles.links}>
                        <Link to="/cookie-notice">Cookie Notice</Link>
                        <Link to="/privacy-notice">Privacy Notice</Link>
                    </span>
                </span>
                <div className={styles.switchesContainer}>
                    {
                        props.showMarketing &&
                            <label>
                                <Switch checked={marketing} onChange={(e) => setMarketing(e)}
                                    height={20} width={40} handleDiameter={18} />
                                <span>Marketing</span>
                            </label>
                    }
                    {
                        props.showPersonalization &&
                            <label>
                                <Switch checked={personalization} onChange={(e) => setPersonalization(e)}
                                    height={20} width={40} handleDiameter={18} />
                                <span>Personalization</span>
                            </label>
                    }
                    {
                        props.showAnalytics &&
                            <label>
                                <Switch checked={analytics} onChange={(e) => setAnalytics(e)}
                                    height={20} width={40} handleDiameter={18} />
                                <span>Analytics</span>
                            </label>
                    }
                </div>
            </div>
            <div className={styles.submitContainer}>
                <button onClick={handleSave}>Save</button>
                <button onClick={handleAcceptAll}>Accept All</button>
            </div>
        </div>
    );
}

export default CookieConsent;
