import React from 'react';
import { Route, Switch, Link, BrowserRouter as Router } from 'react-router-dom';
import styles from './App.module.css';

import Home from './pages/Home';
import Blog from './pages/Blog';
import Terms from './pages/Terms';
import Footer from './components/Footer';
import MaethrilText from './components/MaethrilMedia';
import CookiePolicy from './pages/CookiePolicy';
import PrivacyPolicy from './pages/PrivacyNotice';
import CookieConsent from './components/Cookies/CookieConsent';
import { useCookies } from 'react-cookie';
import ScrollToTop from './components/ScrollToTop';

const App: React.FC = () => {
  const [cookies, setCookie] = useCookies(['maethril_consent']);

  return (
    <div className={styles.mainContainer}>
      <Router>
        <ScrollToTop />
        {
          !cookies["maethril_consent"] &&
            <CookieConsent showMarketing={true}
                           showPersonalization={true}
                           showAnalytics={true} />
        }
        <div className={styles.mainContentWrapper}>
          <nav className={styles.navBar}>
            <Link className={styles.homeLink} to="/">
              <div className={styles.homeContainer}>
                <div className={styles.homeWrapper}>
                  <MaethrilText />
                </div>
              </div>
            </Link>
          </nav>
          <div className={styles.mainContent}>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/blog/:id" component={Blog} />
                <Route path="/terms-of-service" component={Terms} />
                <Route path="/privacy-notice" component={PrivacyPolicy} />
                <Route path="/cookie-notice" component={CookiePolicy} />
                <Route path="/" render={() => (
                  <div className={styles.notFoundContainer}>
                    <h1>404 page not found</h1>
                  </div>
                )} />
            </Switch>
          </div>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
