import React, { useState } from 'react';

import styles from './CookieSettings.module.css';
import { FiSettings } from 'react-icons/fi';
import CookieSettingsMenu from './CookieSettingsMenu';

interface IOpen
{
    open: boolean;
    setClose: () => void;
}

const CookieSettings: React.FC<IOpen> = (props: IOpen) => {
    const [open, setOpen] = useState<boolean>(false);

    function handleClose() {
        setOpen(false);
        props?.setClose();
    }

    return (
        <div>
            {
                !open && !props?.open ?
                    <button className={styles.button}
                            onClick={() => setOpen(true)}>
                        <FiSettings className={styles.buttonIcon} />
                    </button> :
                    <CookieSettingsMenu handleClose={() => handleClose()} />
            }

        </div>
    );
}

export default CookieSettings;
