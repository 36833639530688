import React, { useState, useEffect } from 'react';
import configData from '../config.json';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { ISocialMediaDTO } from '../types/SocialMedia';
import SocialMedia from './SocialMedia';

const Footer: React.FC = () => {
    const [socialMedias, setSocialMedias] = useState<ISocialMediaDTO[]>([]);

    useEffect(() => {
        axios.get<ISocialMediaDTO[]>(configData.SOCIALMEDIA_URL)
            .then(response => setSocialMedias(response.data))
            .catch(error => {});
    }, []);

    return (
        <footer className={styles.container}>
            { socialMedias.length > 0 &&
                <div className={styles.socialMediaContainer}>
                {
                    socialMedias.map(socialMedia =>
                        socialMedia.url &&
                    <div key={socialMedia.id} className={styles.socialMediaWrapper}>
                        <SocialMedia id={socialMedia.id} url={socialMedia.url as string} />
                    </div>
                )}
                </div>
            }
            <div className={styles.linksContainer}>
                <Link className={styles.bottomLink} to="/terms-of-service">
                    <span>Terms Of Service</span>
                </Link>
                <span className={styles.bottomSpacer}></span>
                <Link className={styles.bottomLink} to="/privacy-notice">
                    <span>Privacy Notice</span>
                </Link>
                <span className={styles.bottomSpacer}></span>
                <Link className={styles.bottomLink} to="/cookie-notice">
                    <span>Cookie Notice</span>
                </Link>
            </div>
            <span className={styles.maethril}>© {new Date().getFullYear()} Maethril</span>
        </footer>
    );
}

export default Footer;
