import React, { useEffect, useState } from 'react';
import configData from '../config.json';
import styles from './Newsletter.module.css';
import axios from 'axios';
import Checkbox from './Checkbox';
import { FiChevronRight } from 'react-icons/fi';
import { useCookies } from 'react-cookie';

interface ISubscriber {
    id: number;
    email: string;
    subscriptions: ISubscription[];
}

interface ISubscription {
    id: number;
    name: string;
}

interface ISubscriptionFilter {
    id: number;
    name: string;
    selected: boolean;
}

const Newsletter: React.FC = () => {
    const [cookies, setCookie] = useCookies(['maethril_newsletter']);

    const [email, setEmail] = useState<string>("");
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [subscriptions, setSubscriptions] = useState<ISubscriptionFilter[]>([]);
    const [endState, setEndState] = useState<boolean>(false);

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    useEffect(() => {
        axios.get<ISubscription[]>(configData.SUBSCRIPTIONS_URL)
            .then(response => {
                let subscriptions: ISubscriptionFilter[] = [];
                for(let i = 0; i < response.data.length; ++i)
                {
                    subscriptions.push({
                        id: response.data[i].id,
                        name: response.data[i].name,
                        selected: true
                    });
                }

                setSubscriptions(subscriptions)
            })
            .catch(error => console.log(error))
    }, []);

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>): void {
        if(e.target.value === "") {
            setSuccess(false);
            setStatusMessage("");
        }
        else if(!re.test(String((e.target.value).toLowerCase())))
        {
            setSuccess(false);
            setStatusMessage("Invalid email!");
        }
        else {
            setSuccess(true);
            setStatusMessage("");
        }

        setEmail(e.target.value);
    }

    function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>):void {
        let newSubscriptions: ISubscriptionFilter[] = [];
        subscriptions.forEach(obj => {
            let newTag: ISubscriptionFilter = {
                id: obj.id,
                name: obj.name,
                selected: obj.name === e.target.name ? !obj.selected : obj.selected
            }
            newSubscriptions.push(newTag);
        });

        setSubscriptions(newSubscriptions);
    }

    function handleSubmit(e: React.FormEvent): void {
        e.preventDefault();
        subscribe();
    }

    function subscribe() {
        if(email === "") {
            setSuccess(false);
            setStatusMessage("Email is required!");
            return;
        }

        if(!re.test(String((email).toLowerCase())))
        {
            setSuccess(false);
            setStatusMessage("Invalid email!");
            return;
        }

        let newSubscriber: ISubscriber = {
            id: 0,
            email: email,
            subscriptions: subscriptions.filter(obj => obj.selected).map(obj => ({id: obj.id, name: obj.name}))
        }

        axios({
            method: 'POST',
            url: configData.SUBSCRIBER_URL,
            data: newSubscriber
        })
            .then(response => {
                if(response.status === 200 && response.statusText === "OK")
                {
                    setSuccess(true);
                    setStatusMessage("Thank you for joining our newsletter!");
                    setEmail("");
                    setEndState(true);

                    if(cookies["maethril_consent"].personalization)
                    {
                        let cookie = { hasSubscribed: true }
                        let expiration = new Date();
                        expiration.setDate(expiration.getDate() + 365);

                        setCookie("maethril_newsletter", cookie, {
                            path: "/",
                            secure: true,
                            sameSite: 'lax',
                            expires: expiration
                        });
                    }
                }
            })
            .catch(error => {
                if(error.response.status === 400) {
                    if(error.response.data.errors.Email[0]) {
                        setSuccess(false);
                        setStatusMessage(error.response.data.errors.Email[0]);
                    }
                }
                else {
                    setSuccess(false);
                    setStatusMessage("Something went wrong.");
                }
            });
    }

    if(endState) {
        return (
            <div className={styles.container}>
                <h2>Thank you for joining our newsletter!</h2>
            </div>
        )
    }

    if(cookies["maethril_newsletter"])
        return <div />

    return (
        <div className={styles.container}>
            <h2>Join our newsletter!</h2>
            <form className={styles.formContainer} onSubmit={handleSubmit}>
                <div className={styles.emailContainer}>
                    <input className={styles.email}
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={e => handleEmailChange(e)} />
                    <FiChevronRight className={styles.emailSubmitBtn} onClick={() => subscribe()}/>
                </div>

                <p className={`${styles.statusMessage} ${success ? styles.emailSuccess : styles.emailError}`}>{statusMessage}</p>
                {
                    email !== "" &&
                    <div className={styles.subscriptionsContainer}>
                        <ul>
                            {
                                subscriptions.map(subscription =>
                                    <li key={subscription.id}>
                                        <Checkbox id={subscription.id} name={subscription.name} selected={subscription.selected} onChange={handleCheckboxChange} />
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                }
            </form>
        </div>
    );
}

export default Newsletter;
