import React  from 'react';
import { FiCoffee, FiStar, FiTerminal, FiTool } from 'react-icons/fi';
import { ITagFilter } from '../types/Pagination';
import styles from './TagFilter.module.css';

interface ITagFilterProps {
    tag: ITagFilter;
    onClick: (tag: ITagFilter) => void;
}

const BlogCard: React.FC<ITagFilterProps> = (props: ITagFilterProps) => {
    function getTagIcon(tag: string): JSX.Element {
        switch(tag){
            case "Major":
                return <FiStar key={0} className={styles.tagIcon} />
            case "General":
                return <FiCoffee key={1} className={styles.tagIcon} />
            case "Tech":
                return <FiTool key={2} className={styles.tagIcon} />
            case "Dev Update":
                return <FiTerminal key={3} className={styles.tagIcon} />
        }

        return <></>
    }

    return (
        <div className={`${styles.tagContainer} ${props.tag.selected ? styles.selected : styles.notSelected} `}
             onClick={() => props.onClick(props.tag)}>
            { getTagIcon(props.tag.name) }
            <p className={styles.tagText}>{props.tag.name}</p>
        </div>
    );
}

export default BlogCard;
