import React from 'react';
import styles from './SkeletonBlogCard.module.css';

const SkeletonBlogCard: React.FC = () => {

    return (
        <div className={styles.cardContainer}>
            <div className={styles.contentContainer}>
                <div className={styles.title}>
                </div>
                <div className={styles.bottom}>

                </div>
            </div>
            <div className={styles.thumbnailContainer} />
        </div>
    );
}

export default SkeletonBlogCard;
