import React from 'react';
import styles from './Checkbox.module.css';

interface ICheckboxProps {
    id: number;
    name: string;
    selected: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FC<ICheckboxProps>= (props: ICheckboxProps) => {
    return (
        <div className={styles.container}>
            <input className={styles.inputCheckbox} id={`cbx-${props.id}`} type="checkbox" name={props.name} checked={props.selected} onChange={props.onChange} />
            <label className={styles.labelCheckbox} htmlFor={`cbx-${props.id}`}>
                <span>
                    <svg width="12px" height="10px">
                        <polyline points="1.5 6 4.5 9 10.5 1" />
                    </svg>
                </span>
                <span>{props.name}</span>
            </label>
        </div>

    );
}

export default Checkbox;
